import React, { useContext, useState } from "react";
import { LanguageContext } from "../../context/Languages.jsx";
import ClientValidation from "../../validation/ClientValidation";
import { useHistory } from "react-router-dom";
import { signUpClient } from "../../helpers/apiCalls";
import MyContext from "../../context/Mycontext";

const ClientRegister = (props) => {
  let history = useHistory();
  const { dictionary } = useContext(LanguageContext);
  const { setClient, callback, setCallback, checkedData, setCheckedData } = useContext(MyContext);

  /*--Form Handler start--*/
  const [formVal, setFormVal] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    pw: "",
    repw: "",
    company: "",
    checked: false,
  });

  const formHandler = (e) => {
    e.preventDefault();
    const id = e.target.id;
    const val = e.target.value;
    setFormVal({
      ...formVal,
      [id]: val,
    });
    setCheckedData({});
  };

  const checkboxHandler = () => {
    setFormVal({
      ...formVal,
      checked: !formVal.checked,
    });
  };

  const formNumberHandler = (e) => {
    e.preventDefault();
    const id = e.target.id;
    const val = e.target.value;
    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
    if (rx_live.test(val)) {
      setFormVal({
        ...formVal,
        [id]: val,
      });
    }
    setCheckedData({});
  };

  const registration = async () => {
    let checkedData = ClientValidation(formVal);
    setCheckedData(checkedData);
    if (checkedData.success) {
      let clientData = {
        CompanyName: formVal.company,
        FirstName: formVal.firstname,
        LastName: formVal.lastname,
        Email: formVal.email,
        Phone: formVal.phone,
        Password: formVal.pw
      };

      try {
        const newClient = await signUpClient(clientData);
        if (newClient === 1) {
          history.push("/client-login");
          return alert("User exists please Login");
        }
        setClient(newClient);
        history.push("/client-dashboard");
        setCallback(!callback)
      } catch (error) {
        console.log(error.messages);
      }
    }
  };
  /*--Form Handler end--*/

  return (
    <>
      <div className="registration-page">
        <div className="container">
          <div className="body">
            <h1>
              - {dictionary.clien} {dictionary.registrationTitle} -
            </h1>
          </div>
          <div className="reg-form-row">
            <div className="row-col">
              <input
                type="text"
                name="bs-name"
                id="company"
                placeholder={dictionary.business}
                value={formVal.company}
                onChange={(e) => formHandler(e)}
              />
            </div>
            <div className="row-col">
              <input
                type="text"
                name="firstname"
                id="firstname"
                placeholder="First Name *"
                value={formVal.firstname}
                onChange={(e) => formHandler(e)}
                required
                style={
                  checkedData.borderColors !== undefined
                    ? checkedData.borderColors.firstname
                      ? { border: checkedData.borderColors.firstname }
                      : {}
                    : {}
                }
              />
              <div className="form-err text-left">
                {checkedData.errs !== undefined
                  ? checkedData.errs.firstname
                    ? checkedData.errs.firstname
                    : ""
                  : ""}
              </div>
            </div>
          </div>
          <div className="reg-form-row">
            <div className="row-col">
              <input
                type="text"
                name="lastname"
                id="lastname"
                required
                placeholder="Last Name *"
                value={formVal.lastname}
                onChange={(e) => formHandler(e)}
                style={
                  checkedData.borderColors !== undefined
                    ? checkedData.borderColors.lastname
                      ? { border: checkedData.borderColors.lastname }
                      : {}
                    : {}
                }
              />
              <div className="form-err text-left">
                {checkedData.errs !== undefined
                  ? checkedData.errs.lastname
                    ? checkedData.errs.lastname
                    : ""
                  : ""}
              </div>
            </div>
            <div className="row-col">
              <input
                type="input"
                name="mobilenumber"
                id="phone"
                pattern="[+-]?\d+(?:[.,]\d+)?"
                placeholder="Mobile Number *"
                value={formVal.phone}
                required
                onChange={(e) => formNumberHandler(e)}
                maxLength="11"
                style={
                  checkedData.borderColors !== undefined
                    ? checkedData.borderColors.phone
                      ? { border: checkedData.borderColors.phone }
                      : {}
                    : {}
                }
              />
              <div className="form-err text-left">
                {checkedData.errs !== undefined
                  ? checkedData.errs.phone
                    ? checkedData.errs.phone
                    : ""
                  : ""}
              </div>
            </div>
          </div>
          <div className="reg-form-row">
            <div className="row-col">
              <input
                type="email"
                name="email"
                id="email"
                required
                placeholder="E-mail *"
                value={formVal.email}
                onChange={(e) => formHandler(e)}
                style={
                  checkedData.borderColors !== undefined
                    ? checkedData.borderColors.email
                      ? { border: checkedData.borderColors.email }
                      : {}
                    : {}
                }
              />
              <div className="form-err text-left">
                {checkedData.errs !== undefined
                  ? checkedData.errs.email
                    ? checkedData.errs.email
                    : ""
                  : ""}
              </div>
            </div>
            <div className="row-col">
              <input
                type="password"
                name="password"
                required
                id="pw"
                placeholder={dictionary.passwort}
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                value={formVal.pw}
                onChange={(e) => formHandler(e)}
                style={
                  checkedData.borderColors !== undefined
                    ? checkedData.borderColors.pw
                      ? { border: checkedData.borderColors.pw }
                      : {}
                    : {}
                }
              ></input>
              <div className="form-err text-left">
                {checkedData.errs !== undefined
                  ? checkedData.errs.pw
                    ? checkedData.errs.pw
                    : ""
                  : ""}
              </div>
            </div>
          </div>
          <div className="reg-form-row">
            <div className="row-col">
              <input
                type="password"
                name="repassword"
                id="repw"
                placeholder={dictionary.retypePassword}
                value={formVal.repw}
                onChange={(e) => formHandler(e)}
                required
                style={
                  checkedData.borderColors !== undefined
                    ? checkedData.borderColors.repw
                      ? { border: checkedData.borderColors.repw }
                      : {}
                    : {}
                }
              ></input>
              <div className="form-err text-left">
                {checkedData.errs !== undefined
                  ? checkedData.errs.repw
                    ? checkedData.errs.repw
                    : ""
                  : ""}
              </div>
            </div>
          </div>

          <div className="reg-form-terms">
            <input
              type="checkbox"
              name="check"
              id="terms_conditions"
              onChange={checkboxHandler}
            />
            <div
              className="terms-conditions"
              style={
                checkedData.borderColors !== undefined
                  ? checkedData.borderColors.checked
                    ? { color: checkedData.borderColors.checked }
                    : {}
                  : {}
              }
            >
              <label for="terms_conditions" check>
                {dictionary.termsAccept}
                <a
                  href="https://kurier247.de/privacy-policy.aspx"
                  className="content"
                  style={
                    checkedData.borderColors !== undefined
                      ? checkedData.borderColors.checked
                        ? { color: checkedData.borderColors.checked }
                        : {}
                      : {}
                  }
                >
                  {" "}
                  {dictionary.termscondition}
                </a>
              </label>
            </div>
          </div>
          <div className="form-button">
            <button
              type="submit"
              id="client-register"
              color="success"
              onClick={() => registration()}
            >
              {dictionary.send}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default ClientRegister;
