import React, { useContext } from "react";
import { Container, Row, Col, Input, FormGroup } from "reactstrap";
import { FiMail, FiPhone } from "react-icons/fi";
import { LanguageContext } from "../context/Languages.jsx";
import { FaFacebook } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import { ImInstagram } from "react-icons/im";
import { Link } from "react-router-dom";

const Footer = (props) => {
  const { dictionary } = useContext(LanguageContext);
  return (
    <>
      <div className="footer">
        <Container>
          <Row className="footer-items">
            <Col lg={3} md={6} className="mt-4 p-4">
              <h1>{dictionary.quickLinks}</h1>
              <Col md={6} className="ml-auot mr-auto line">
                <hr />
              </Col>
              <h4 className="mt-3">{dictionary.courier} Service</h4>
              <h4 className="mt-3"> {dictionary.removal}</h4>
              <h4 className="mt-3">Bulky waste recycling</h4>
            </Col>
            <Col lg={3} md={6} className="mt-4 p-4">
              <h1>Benefits</h1>
              <Col md={6} className="ml-auot mr-auto line">
                <hr />
              </Col>
              <h4 className="mt-3">Partner Benefits</h4>
              <h4 className="mt-3">Terms and Conditions</h4>
              <h4 className="mt-3">Data Policy</h4>
              <h4 className="mt-3">Impressum</h4>
            </Col>
            <Col lg={3} md={6} className="mt-4 p-4">
              <h1>Newsletter</h1>
              <Col md={6} className="ml-auot mr-auto line">
                <hr />
              </Col>
              <div className="mt-3">
                Subscribe our newsletter and you can get free notified delivered
                straight to your inbox. Subscribing below.
              </div>
              <div className="mt-3">
                <FormGroup>
                  <Input
                    className="email text-center"
                    type="email"
                    name="email"
                    id="eamil"
                    placeholder="Enter your email..."
                  />
                  <button className='button-common mt-3'>
                    Submit
                  </button>
                </FormGroup>
              </div>
            </Col>
            <Col lg={3} md={6} className="mt-4 p-4">
              <h1>{dictionary.needhelp} ?</h1>
              <Col md={6} className="ml-auot mr-auto line">
                <hr />
              </Col>
              <div className="mt-3 help-infor">
                <div className="help-infor-link">
                  <a href="mailto:info@kurier247.de">
                    <FiMail />
                    {"   "}info@kurier247.de
                  </a>
                </div>

                <div className="help-infor-link mt-2">
                  <a href="tel:01736086617">
                    <FiPhone />
                    {"   "}0173 6086617
                  </a>
                </div>

                <div className="help-infor-link mt-2">
                  <a href="tel: 030 54811500">
                    <FiPhone />
                    {"   "}030 54811500
                  </a>
                </div>
              </div>
              <div className="mt-2">
                <FormGroup>
                  <button className="button-common mt-2" color="success">
                    <Link to="/contact-us" className="links-button">
                      {dictionary.contactus}
                    </Link>
                  </button>
                </FormGroup>
              </div>
            </Col>
          </Row>
        </Container>
        <div
          className="footer-bottom text-center"
          target="_blank"
          rel="noopener noreferrer"
        >
            <p className="text">Kurier 247 | All rights reserved</p>
          <div className="social-share p-2">
            <a href="https://www.facebook.com/Kurier247/?modal=admin_todo_tour/">
              <FaFacebook fontSize="30px" />
            </a>
            <a
              href="https://twitter.com/247Kurier"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillTwitterCircle fontSize="33px" className="ml-2" />
            </a>
            <a
              href="https://www.instagram.com/kurier247/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ImInstagram fontSize="26px" className="ml-2" />
            </a>
          </div>
            <p className="text">
              Developed by{" "}|
              <a href="https://ferujahammed.github.io/"> Feruj Ahammed </a>
            </p>
          </div>
        </div>
    </>
  );
};

export default Footer;
