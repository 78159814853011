import React from "react";
import { Line, Bar } from "react-chartjs-2";

const DashBoard = () => {
  const data = {
    labels: [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Salse 2020",
        data: [2, 9, 3, 5, 2, 3, 5, 6, 2, 11, 7, 10],
        fill: true,
        borderColor: ["rgba(255, 206, 86, 0.5)"],
        backgroundColor: ["rgba(255, 206, 86, 0.5)"],
        pointBackgroundColor: "rgba(255, 206, 86, 0.5)",
        pointBorderColor: "rgba(255, 206, 86, 0.5)",
        borderWidth: 0,
      },
      {
        label: "Salse 2021",
        data: [3, 6, 2, 1, 7, 5, 5, 4, 3, 7, 5, 7],
        fill: true,
        borderColor: ["rgba(54,162,235,0.5)"],
        backgroundColor: ["rgba(54,162,235,0.5)"],
        pointBackgroundColor: "rgba(54,162,235,0.5)",
        pointBorderColor: "rgba(54,162,235,0.5)",
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: { 
        min: 0, max: 12, stepSize: 0.5,
        title: {
          display: true,
          text: "Line Chat",
        }
      },
    },
  };

  const data1 = {
    labels: [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [2, 7, 3, 5, 2, 3, 2, 7, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
      },
    ],
  };

  const options1 = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <div className="full-width-height admin-dashboard">
      <h1>Admin DashBoard </h1>
      <Line data={data} options={options} />
      <Bar data={data1} options={options1} />
    </div>
  );
};

export default DashBoard;
