import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { LanguageContext } from "../../context/Languages";
import { Link } from "react-router-dom";
import MyContext from "../../context/Mycontext";
import { clientLogin } from "../../helpers/apiCalls";
import { useHistory } from "react-router-dom";

const ClientLogin = (props) => {
  let history = useHistory();
  const { register, handleSubmit } = useForm();
  const { dictionary } = useContext(LanguageContext);

  const context = useContext(MyContext);
  const { loading, setLoading, setClient, callback, setCallback } = context;

  const onSubmit = async (data) => {
    setLoading(true);
    setTimeout(async () => {
      try {
        const result = await clientLogin(data);
        if (result === 1) {
          history.push("/client-registeration");
          setLoading(false);
          return alert("Email not found on our Client List, Please Register");
        }
        if (result === 2) {
          setLoading(false);
          return alert("Password dosenot match, try again..");
        }
        setClient(result);
        setCallback(!callback);
        history.push("/client-dashboard");
      } catch (error) {
        console.log(error);
      }
    }, 1000);
  };

  return (
    <>
      <div className="login-form-container">
        <h1 className="title">{dictionary.client} LOGIN</h1>

        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="email"
            placeholder="Email"
            defaultValue="ferujahammed2020@gmail.com"
            {...register("Email")}
          />
          <input
            type="password"
            placeholder="Password"
            defaultValue="866505@Gmail"
            {...register("Password")}
          />
          <div className="login-button">
            <button>{loading ? "Logging in..." : "Login"}</button>
          </div>
        </form>
        <div className="login-footer">
          <div className="mr-3">
            <input
              className="mr-3"
              type="checkbox"
              name="check"
              id="stay-logged-in"
            />
            <label htmlFor="stay-logged-in">Stay Logged In</label>
          </div>
          <div className="mx-3">
            <a href="/">Forgot Password?</a>
          </div>
          <div className="ml-3">
            Don't have an account?{"  "}
            <Link to="/client-registeration">Client Registration</Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default ClientLogin;
