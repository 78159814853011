import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MyContext from "../../context/Mycontext";
import { useForm } from "react-hook-form";
import {
  updateOrder,
  updateStartAddress,
  updateDelAddress,
  updateStopAddress,
  addstopage,
  delStopage,
} from "../../helpers/apiCalls";

const OrderDetails = () => {
  const params = useParams();
  const {
    allOrders,
    order,
    setOrder,
    Packages,
    partners,
    callback,
    setCallback,
    addIdSt,
    setAddIdSt,
  } = useContext(MyContext);
  const [btn, setBtn] = useState(false);
  const [addIdS, setAddIdS] = useState(false);
  const [addIdD, setAddIdD] = useState(false);

  const { register, handleSubmit, reset } = useForm();
  const {
    register: register2,
    reset: reset2,
    handleSubmit: handleSubmit2,
  } = useForm();

  useEffect(() => {
    if (params.id) {
      allOrders.forEach((item) => {
        if (item._id === params.id) {
          item.BookingDate = item.BookingDate?.slice(0, 10);
          setOrder(item);
          reset(item);
        }
      });
    }
  }, [allOrders, params.id, setOrder, reset]);

  const basePrice = order.PackageId?.PackagePrice;
  const KmPrice = (order.PackageId?.PricePerKm * order.Total_KM).toFixed(2) * 1;
  const StopagePrice =
    (order.PackageId?.StoppagePrice * order.StopagesAddress?.length).toFixed(
      2
    ) * 1;
  const loadingPrice = (order.Load_Unload / 5) * order.PackageId?.Price5min;
  const helpingPrice =
    (order.Load_Unload_Help / 60) * order.PackageId?.PricePerHelper;
  const Netto =
    (basePrice + KmPrice + StopagePrice + loadingPrice + helpingPrice).toFixed(
      2
    ) * 1;
  const Vat = (Netto * (order.PackageId?.MwSt / 100)).toFixed(2) * 1;
  const total = (Netto + Vat).toFixed(2) * 1;
  if (order.length === 0) return null;

  const onSubmit = async (data) => {
    const res = await updateOrder(order._id, data);
    setOrder(res);
    setCallback(!callback);
    reset(res);
  };

  const onSubmit2 = async (data) => {
    let result;
    if (addIdS) {
      result = await updateStartAddress(order._id, data);
    } else if (addIdSt) {
      result = await updateStopAddress(order._id, addIdSt, data);
    } else if (addIdD) {
      result = await updateDelAddress(order._id, data);
    } else {
      result = await addstopage(order._id, data);
    }
    setOrder(result);
    reset2({});
  };
  const clear = () => {
    setAddIdS(false);
    setAddIdD(false);
    setAddIdSt(null);
    reset2({});
  };
  const handleStopage = async (stId) => {
    const res = await delStopage(order._id, stId);
    setOrder(res);
    reset2({});
  };

  return (
    <div className="full-width-height">
      <div className="order-details">
        <h1>Order Details</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-one">
            <input
              type="number"
              placeholder="Order ID"
              defaultValue={order.OrderNumber}
              disabled
            />

            <input
              type="text"
              placeholder="Client"
              defaultValue={order.ClientId?.FullName}
              disabled
            />
            {/* <input type="text" defaultValue={order.PackageId?.Title} disabled /> */}
            <select {...register("PackageId")} onClick={() => setBtn(true)}>
              <option value={order.PackageId}>{order.PackageId?.Title}</option>
              {Packages?.map((item) => (
                <option
                  value={item._id}
                  key={item._id}
                  style={
                    order.PackageId?.Title === item.Title
                      ? { display: "none" }
                      : { display: "block" }
                  }
                >
                  {item.Title}
                </option>
              ))}
            </select>
            <input
              onClick={() => setBtn(true)}
              type="date"
              id="BookingDate"
              placeholder="Date"
              defaultValue={order.BookingDate?.slice(0, 10)}
              {...register("BookingDate")}
            />
            <select {...register("BookingTime")} onClick={() => setBtn(true)}>
              <option value={order.BookingTime}>{order.BookingTime}</option>
              <option value="07:00 - 07:30">07:00 - 07:30</option>
              <option value="07:30 - 08:00">07:30 - 08:00</option>
              <option value="08:00 - 08:30">08:00 - 08:30</option>
              <option value="08:30 - 09:00">08:30 - 09:00</option>
              <option value="09:00 - 09:30">09:00 - 09:30</option>
              <option value="09:30 - 10:00">09:30 - 10:00</option>
              <option value="10:00 - 10:30">10:00 - 10:30</option>
              <option value="10:30 - 11:00">10:30 - 11:00</option>
              <option value="11:00 - 11:30">11:00 - 11:30</option>
              <option value="11:30 - 12:00">11:30 - 12:00</option>
              <option value="12:00 - 12:30">12:00 - 12:30</option>
              <option value="12:30 - 13:00">12:30 - 13:00</option>
              <option value="13:00 - 13:30">13:00 - 13:30</option>
              <option value="13:30 - 14:00">13:30 - 14:00</option>
              <option value="14:00 - 14:30">14:00 - 14:30</option>
              <option value="14:30 - 15:00">14:30 - 15:00</option>
              <option value="15:00 - 15:30">15:00 - 15:30</option>
              <option value="15:30 - 16:00">15:30 - 16:00</option>
              <option value="16:00 - 16:30">16:00 - 16:30</option>
              <option value="16:30 - 17:00">16:30 - 17:00</option>
              <option value="17:00 - 17:30">17:00 - 17:30</option>
              <option value="17:30 - 18:00">17:30 - 18:00</option>
              <option value="18:00 - 18:30">18:00 - 18:30</option>
              <option value="18:30 - 19:00">18:30 - 19:00</option>
              <option value="19:00 - 19:30">19:00 - 19:30</option>
              <option value="19:30 - 20:00">19:30 - 20:00</option>
              <option value="20:00 - 20:30">20:00 - 20:30</option>
              <option value="20:30 - 21:00">20:30 - 21:00</option>
            </select>
            <input
              onClick={() => setBtn(true)}
              type="number"
              placeholder="Distance"
              defaultValue={order.Total_KM}
              {...register("Total_KM")}
            />
            <input
              onClick={() => setBtn(true)}
              type="number"
              placeholder="Waiting Minuts"
              defaultValue={order.Load_Unload}
              {...register("Load_Unload")}
            />
            <input
              onClick={() => setBtn(true)}
              type="number"
              placeholder="Helping Minuts"
              defaultValue={order.Load_Unload_Help}
              {...register("Load_Unload_Help")}
            />
            <input
              type="number"
              placeholder="Total"
              defaultValue={total}
              disabled
            />
            <select {...register("PaymentType")} onClick={() => setBtn(true)}>
              <option value={order?.PaymentType}>{order?.PaymentType}</option>
              <option
                value="CASH"
                style={
                  order?.PaymentType === "CASH"
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                CASH
              </option>
              <option
                value="CARD"
                style={
                  order?.PaymentType === "CARD"
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                CARD
              </option>
              <option
                value="SEPA"
                style={
                  order?.PaymentType === "SEPA"
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                SEPA
              </option>
              <option
                value="INVOICE"
                style={
                  order?.PaymentType === "INVOICE"
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                INVOICE
              </option>
            </select>
            <select {...register("OrderStatus")} onClick={() => setBtn(true)}>
              <option value={order?.OrderStatus}>{order?.OrderStatus}</option>
              
              <option
                value="Pending"
                style={
                  order?.OrderStatus === "Pending"
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                Pending
              </option>
              <option
                value="Reject"
                style={
                  order?.OrderStatus === "Reject"
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                Reject
              </option>
              <option
                value="Accept"
                style={
                  order?.OrderStatus === "Accept"
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                Accept
              </option>
              <option
                value="Done"
                style={
                  order?.OrderStatus === "Done"
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                Done
              </option>
              <option
                value="Cancel"
                style={
                  order?.OrderStatus === "Cancel"
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                Cancel
              </option>
            </select>
            <input
              type="text"
              placeholder="Partner"
              defaultValue={order.PartnerId?.City}
              disabled
            />
            <select {...register("PartnerId")} onClick={() => setBtn(true)}>
              <option value={order.PartnerId}>
                {order.PartnerId?.FullName}
              </option>
              {partners?.map((partner) => (
                <option
                  value={partner._id}
                  key={partner._id}
                  style={
                    order.PartnerId?.FullName === partner.FullName
                      ? { display: "none" }
                      : { display: "block" }
                  }
                >
                  {partner.FullName}
                </option>
              ))}
            </select>
          </div>
          {btn && (
            <div className="form-submit-button">
              <button className="button-common">Update and Save</button>
            </div>
          )}
        </form>
        <div className="client-order-details">
          <form onSubmit={handleSubmit2(onSubmit2)}>
            <div className="delivery-addresses">
              <div className="all-Address">
                <div className="address">
                  <h2>Start-Point</h2>
                  <br />
                  <h3>{order.StartAddress?.Name}</h3>
                  <p>
                    {order.StartAddress?.Street +
                      " " +
                      order.StartAddress?.HouseNo}
                  </p>
                  <p>
                    {order.StartAddress?.PostCode +
                      " " +
                      order.StartAddress?.City}
                  </p>
                  <p>{order.StartAddress?.Phone}</p>
                  <p>{order.StartAddress?.Comments}</p>
                  {/* <p>Last Update: {order.updatedAt?.slice(0, 16)}</p> */}
                  <div className="bill-address-button">
                    <button
                      type="button"
                      className="button-common px-5"
                      onClick={() => {
                        setAddIdS(true);
                        setAddIdD(false);
                        setAddIdSt(null);
                        reset2(order.StartAddress);
                      }}
                    >
                      Edite
                    </button>
                  </div>
                </div>
                {order.StopagesAddress?.map((address, index) => {
                  return (
                    <div className="address" key={index}>
                      <h2>Stopage {index + 1}</h2>
                      <br />
                      <h3>{address.Name}</h3>
                      <p>{address.Street + " " + address.HouseNo}</p>
                      <p>{address.PostCode + " " + address.City}</p>
                      <p>{address.Phone}</p>
                      <p>{address.Comments}</p>
                      <div className="bill-address-button">
                        <button
                          type="button"
                          className="button-common px-5"
                          onClick={() => {
                            setAddIdS(false);
                            setAddIdD(false);
                            setAddIdSt(address._id);
                            reset2(address);
                          }}
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="button-clear mx-3 px-5"
                          onClick={() => handleStopage(address._id)}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  );
                })}
                <div className="address">
                  <h2>Delivery-Point</h2>
                  <br />
                  <h3>{order.DeliveryAddress?.Name}</h3>
                  <p>
                    {order.DeliveryAddress?.Street +
                      " " +
                      order.DeliveryAddress?.HouseNo}
                  </p>
                  <p>
                    {order.DeliveryAddress?.PostCode +
                      " " +
                      order.DeliveryAddress?.City}
                  </p>
                  <p>{order.DeliveryAddress?.Phone}</p>
                  <p>{order.DeliveryAddress?.Comments}</p>
                  <div className="bill-address-button">
                    <button
                      type="button"
                      className="button-common px-5"
                      onClick={() => {
                        setAddIdS(false);
                        setAddIdSt(null);
                        setAddIdD(true);
                        reset2(order.DeliveryAddress);
                      }}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="addresses-form">
              <h1>Add Delivery Address</h1>
              <input
                type="text"
                placeholder="Full Name"
                {...register2("Name")}
              />
              <input
                type="text"
                placeholder="Street"
                {...register2("Street")}
              />
              <input placeholder="House Number" {...register2("HouseNo")} />
              <input
                type="input"
                maxLength="5"
                placeholder="PostCode"
                {...register2("PostCode")}
              />
              <input type="text" placeholder="City" {...register2("City")} />
              <input
                type="input"
                maxLength="11"
                placeholder="Mobile No"
                {...register2("Phone")}
              />
              <input
                type="text"
                placeholder="Comments"
                {...register2("Comments")}
              />
              <div className="submit">
                <button className="button-clear" type="button" onClick={clear}>
                  Clear
                </button>
                <button className="button-common">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
