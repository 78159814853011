import React, { useEffect, useContext, useState } from "react";
import { Container, CardImg, Row, Col } from "reactstrap";
import ServicesSearch from "./ServicesSearch.jsx";
import { API } from "../utils/index.jsx";
import { LanguageContext } from "../context/Languages.jsx";
import axios from "axios";
const AboutUs = () => {
  const { userLanguage } = useContext(LanguageContext);
  const [data, setData] = useState({});
  useEffect(() => {
    function getServiceData() {
      var requestUrl = API.GET_ABOUTUS_DETAILS;
      requestUrl =
        userLanguage === "en" ? requestUrl + "0/4" : requestUrl + "1/4";
      axios
        .get(`${requestUrl}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getServiceData();
  }, [userLanguage]);
  return (
    <>
      <div className="service-details-main">
        <Container>
          <Row>
            <Col md={12} className="ml-auto mr-auto">
              <h2 className="service-details-title text-center">
                {data.length !== undefined ? data[0].Title : ""}
              </h2>
              <div className="service-details-img">
                {data.length !== undefined ? (
                  <CardImg src={"../assets/" + data[0].BigImage} />
                ) : (
                  ""
                )}
              </div>
              {data.length !== undefined ? data[0].BigDetails : ""}
            </Col>
          </Row>
        </Container>
        <ServicesSearch />
      </div>
    </>
  );
};

export default AboutUs;
