import React, { useContext } from "react";
import MyContext from "../../context/Mycontext";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";
import { updatePartner } from "../../helpers/apiCalls";

const PartnerDetails = () => {
  const params = useParams();
  const { register, handleSubmit} = useForm();
  const { partners, callback, setCallback } = useContext(MyContext);
  const [selectedPartner, setSelectedPartner] = useState({});
  const [btn, setBtn] = useState(false);

  useEffect(() => {
    if (params.id) {
      partners.forEach((partner) => {
        if (partner._id === params.id) {
          setSelectedPartner(partner);
        }
        return partner;
      });
    }
  }, [callback, params.id, partners]);

  const onSubmit = async (data) => {
    const res = await updatePartner(selectedPartner._id, data);
    setSelectedPartner(res);
    setCallback(!callback);
  };

  console.log("selectedPartner", selectedPartner);

  return (
    <div className="admin-partner-details">
      <h1>Partner Details</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-body">
          <div className="form">
            <label>Salutation : </label>
            {/* <input
              onClick={() => setBtn(true)}
              type="text"
              placeholder="Salutation"
              defaultValue={selectedPartner.Salutation}
              {...register("Salutation")}
            /> */}
            <select {...register("Salutation")} onClick={() => setBtn(true)} >
                <option value={selectedPartner?.Salutation}>
                  {selectedPartner?.Salutation}
                </option>
                <option
                  value="Mr"
                  style={
                    selectedPartner?.Salutation === "Mr"
                      ? { display: "none" }
                      : { display: "block" }
                  }
                >
                  Mr
                </option>
                <option
                  value="Ms"
                  style={
                    selectedPartner?.Salutation === "Ms"
                      ? { display: "none" }
                      : { display: "block" }
                  }
                >
                  Ms
                </option>
              </select>
          </div>
          <div className="form">
            <label>FirstName : </label>
            <input
              onClick={() => setBtn(true)}
              type="text"
              placeholder="FirstName"
              defaultValue={selectedPartner.FirstName}
              {...register("FirstName")}
            />
          </div>
        </div>
        <div className="form-body">
          <div className="form">
            <label>FamilyName : </label>
            <input
              onClick={() => setBtn(true)}
              type="text"
              placeholder="FamilyName"
              defaultValue={selectedPartner.FamilyName}
              {...register("FamilyName")}
            />
          </div>
          <div className="form">
            <label>FullName : </label>
            <input
              type="text"
              defaultValue={selectedPartner.FullName}
              disabled
            />
          </div>
        </div>
        <div className="form-body">
          <div className="form">
            <label>Street : </label>
            <input
              onClick={() => setBtn(true)}
              type="text"
              placeholder="Street"
              defaultValue={selectedPartner.Street}
              {...register("Street")}
            />
          </div>
          <div className="form">
            <label>HouseNumber : </label>
            <input
              onClick={() => setBtn(true)}
              type="text"
              placeholder="HouseNumber"
              defaultValue={selectedPartner.HouseNumber}
              {...register("HouseNumber")}
            />
          </div>
        </div>
        <div className="form-body">
          <div className="form">
            <label>PostCode : </label>
            <input
              onClick={() => setBtn(true)}
              type="input"
              maxLength="5"
              placeholder="PostCode"
              defaultValue={selectedPartner.PostCode}
              {...register("PostCode")}
            />
          </div>
          <div className="form">
            <label>City : </label>
            <input
              onClick={() => setBtn(true)}
              type="text"
              placeholder="City"
              defaultValue={selectedPartner.City}
              {...register("City")}
            />
          </div>
        </div>
        <div className="form-body">
          <div className="form">
            <label>Country : </label>
            <input
              onClick={() => setBtn(true)}
              type="text"
              placeholder="Country"
              defaultValue={selectedPartner.Country}
              {...register("Country")}
            />
          </div>
          <div className="form">
            <label>Email : </label>
            <input
              onClick={() => setBtn(true)}
              type="email"
              placeholder="Email"
              defaultValue={selectedPartner.Email}
              {...register("Email")}
            />
          </div>
        </div>
        <div className="form-body">
          <div className="form">
            <label>MobileNumber : </label>
            <input
              onClick={() => setBtn(true)}
              type="tel"
              placeholder="MobileNumber"
              defaultValue={selectedPartner.MobileNumber}
              {...register("MobileNumber")}
            />
          </div>
          <div className="form">
            <label>Nationality : </label>
            <input
              onClick={() => setBtn(true)}
              type="text"
              placeholder="Nationality"
              defaultValue={selectedPartner.Nationality}
              {...register("Nationality")}
            />
          </div>
        </div>
        <div className="form-body">
          <div className="form">
            <label>Status : </label>
            {/* <input
              onClick={() => setBtn(true)}
              type="text"
              placeholder="Status"
              defaultValue={selectedPartner.Status}
              {...register("Status")}
            /> */}
            <select {...register("Status")} onClick={() => setBtn(true)} >
                <option value={selectedPartner?.Status}>
                  {selectedPartner?.Status}
                </option>
                <option
                  value="Active"
                  style={
                    selectedPartner?.Status === "Active"
                      ? { display: "none" }
                      : { display: "block" }
                  }
                >
                  Active
                </option>
                <option
                  value="Pending"
                  style={
                    selectedPartner?.Status === "Pending"
                      ? { display: "none" }
                      : { display: "block" }
                  }
                >
                  Pending
                </option>
                <option
                  value="OnHold"
                  style={
                    selectedPartner?.Status === "OnHold"
                      ? { display: "none" }
                      : { display: "block" }
                  }
                >
                  OnHold
                </option>
                <option
                  value="Suspend"
                  style={
                    selectedPartner?.Status === "Suspend"
                      ? { display: "none" }
                      : { display: "block" }
                  }
                >
                  Suspend
                </option>
              </select>
          </div>
          <div className="form">
            <label>RegisterAs : </label>
            <select {...register("RegisterAs")} onClick={() => setBtn(true)}>
              <option value={selectedPartner?.RegisterAs}>
                {selectedPartner?.RegisterAs}
              </option>
              <option
                value="Self-Employed"
                style={
                  selectedPartner?.RegisterAs === "Self-Employed"
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                Self-Employed
              </option>
              <option
                value="Worker"
                style={
                  selectedPartner?.PaymentType === "Worker"
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                Worker
              </option>
            </select>
          </div>
        </div>
        <div className="form-body">
          <div className="form">
            <label>emailIsVerified : </label>
            <input
              onClick={() => setBtn(true)}
              type="boolean"
              placeholder="emailIsVerified"
              defaultValue={selectedPartner.emailIsVerified}
              {...register("emailIsVerified")}
            />
          </div>
          <div className="form">
            <label>Commission : </label>
            <input
              onClick={() => setBtn(true)}
              type="number"
              placeholder="Commission"
              defaultValue={selectedPartner.Commission}
              {...register("Commission")}
            />
          </div>
        </div>
        <div className="form-body">
          <div className="form">
            <label>InterestedWorkingAreas : </label>
            <input
              onClick={() => setBtn(true)}
              type="password"
              placeholder="InterestedWorkingAreas"
              defaultValue={selectedPartner.InterestedWorkingAreas}
              {...register("InterestedWorkingAreas")}
            />
          </div>
          <div className="form">
            <label>Password : </label>
            <input
              onClick={() => setBtn(true)}
              type="password"
              placeholder="Password"
              defaultValue={selectedPartner.Password}
              {...register("Password")}
            />
          </div>
        </div>
        <div className="form-body">
          <div className="form">
            <label>Date Of Birth : </label>
            <input
              onClick={() => setBtn(true)}
              type="date"
              placeholder="DOB"
              defaultValue={selectedPartner.DOB?.slice(0,10)}
              {...register("DOB")}
            />
          </div>
          <div className="form">
            <label>IdPassNumber : </label>
            <input
              onClick={() => setBtn(true)}
              type="text"
              placeholder="IdPassNumber"
              defaultValue={selectedPartner.IdPassNumber}
              {...register("IdPassNumber")}
            />
          </div>
        </div>
        <div className="form-body">
          <div className="form">
            <label>Validity : </label>
            <input
              onClick={() => setBtn(true)}
              type="date"
              placeholder="Validity"
              defaultValue={selectedPartner.Validity?.slice(0,10)}
              {...register("Validity")}
            />
          </div>
          <div className="form">
            <label>InterestedWorkingField : </label>
            <input
              onClick={() => setBtn(true)}
              type="text"
              placeholder="InterestedWorkingField"
              defaultValue={selectedPartner.InterestedWorkingField}
              {...register("InterestedWorkingField")}
            />
          </div>
        </div>
        <div className="form-body">
          <div className="form">
            <label>ChooseKm : </label>
            <input
              onClick={() => setBtn(true)}
              type="number"
              placeholder="ChooseKm"
              defaultValue={selectedPartner.ChooseKm}
              {...register("ChooseKm")}
            />
          </div>
          <div className="form">
            <label>LanguageSkills : </label>
            <input
              onClick={() => setBtn(true)}
              type="text"
              placeholder="LanguageSkills"
              defaultValue={selectedPartner.LanguageSkills}
              {...register("LanguageSkills")}
            />
          </div>
        </div>
        <div className="form-body">
          <div className="form">
            <label>IdPassportFront : </label>
            <input
              onClick={() => setBtn(true)}
              type="text"
              placeholder="IdPassportFront"
              defaultValue={selectedPartner.IdPassportFront}
              {...register("IdPassportFront")}
            />
          </div>
          <div className="form">
            <label>IdPassportBack : </label>
            <input
              onClick={() => setBtn(true)}
              type="text"
              placeholder="IdPassportBack"
              defaultValue={selectedPartner.IdPassportBack}
              {...register("IdPassportBack")}
            />
          </div>
        </div>
        <div className="form-body">
          <div className="form">
            <label>BusinessRegistration : </label>
            <input
              onClick={() => setBtn(true)}
              type="text"
              placeholder="BusinessRegistration"
              defaultValue={selectedPartner.BusinessRegistration}
              {...register("BusinessRegistration")}
            />
          </div>
          <div className="form">
            <label>TaxIdFile : </label>
            <input
              onClick={() => setBtn(true)}
              type="text"
              placeholder="TaxIdFile"
              defaultValue={selectedPartner.TaxIdFile}
              {...register("TaxIdFile")}
            />
          </div>
        </div>
        <div className="form-body">
          <div className="form">
            <label>IdentificationNumber : </label>
            <input
              onClick={() => setBtn(true)}
              type="input"
              placeholder="IdentificationNumber"
              defaultValue={selectedPartner.IdentificationNumber}
              {...register("IdentificationNumber")}
            />
          </div>
          <div className="form">
            <label>TaxId : </label>
            <input
              onClick={() => setBtn(true)}
              type="input"
              placeholder="TaxId"
              defaultValue={selectedPartner.TaxId}
              {...register("TaxId")}
            />
          </div>
        </div>
        <div className="form-body">
          <div className="form">
            <label>ProfilePic : </label>
            <input
              onClick={() => setBtn(true)}
              type="text"
              placeholder="ProfilePic"
              defaultValue={selectedPartner.ProfilePic}
              {...register("ProfilePic")}
            />
          </div>
          <div className="form">
            <label>BankIBAN : </label>
            <input
              onClick={() => setBtn(true)}
              type="input"
              placeholder="BankIBAN"
              defaultValue={selectedPartner.BankIBAN}
              {...register("BankIBAN")}
            />
          </div>
        </div>
        <div className="form-body">
          <div className="form">
            <label>PoliceClearence : </label>
            <input
              onClick={() => setBtn(true)}
              type="text"
              placeholder="PoliceClearence"
              defaultValue={selectedPartner.PoliceClearence}
              {...register("PoliceClearence")}
            />
          </div>
          <div className="form">
            <label>googleId : </label>
            <input
              onClick={() => setBtn(true)}
              type="input"
              placeholder="googleId"
              defaultValue={selectedPartner.googleId}
              {...register("googleId")}
            />
          </div>
        </div>
        {btn && (
          <div className="form-submit-button">
            <button className="button-common">Update and Save</button>
          </div>
        )}
      </form>
    </div>
  );
};

export default PartnerDetails;
