import * as EmailValidator from 'email-validator';

const ClientValidation = (val) => {
    let errs = {};
    let borderColors = {};
    const redData = "2px solid red";
    let success = false;
    let isValidationFailed = false;


    if (val.firstname.trim() === "") {
        // errs.firstname = "Required";
        borderColors.firstname = redData;
        isValidationFailed = true;
    } 
    
    if (val.phone.trim() === "") {
        // errs.phone = "Required";
        borderColors.phone = redData;
        isValidationFailed = true;
    } 
    if (val.lastname.trim() === "") {
        // errs.lastname = "Required";
        borderColors.lastname = redData;
        isValidationFailed = true;
    } 
    if (val.email.trim() === "" || !EmailValidator.validate(val.email.trim())) {
        // errs.email = "Email error&include @";
        borderColors.email = redData;
        isValidationFailed = true;
    } 

    if (val.pw.trim() === "") {
        // errs.pw = "Required";
        borderColors.pw = redData;
        isValidationFailed = true;
    } 

    if (val.repw.trim() === "") {
        // errs.repw = "Required";
        borderColors.repw = redData;
        isValidationFailed = true;
    } 
    if (val.pw.trim() !== "" && val.repw.trim() !== "" && (val.pw.trim() !== val.repw.trim() || val.pw.trim().length < 6)) {
        errs.pw = "Password does not match.";
        errs.repw = "Password does not match.";
        borderColors.pw = redData;
        borderColors.repw = redData;
        isValidationFailed = true;
    } 
    if (val.checked === false) {
        borderColors.checked = "red";
        isValidationFailed = true;
    }       
    if(!isValidationFailed) {
        success = true;
    }
    
    return {
        errs,
        borderColors,
        success
    };

} 

export default ClientValidation;