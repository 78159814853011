import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../context/Languages.jsx";
import MyContext from "../../context/Mycontext";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  getClientOrders,
  updateStartAddress,
  updateDelAddress,
  updateStopAddress,
  addstopage,
  delStopage,
} from "../../helpers/apiCalls.js";

const ClientOrders = (props) => {
  const history = useHistory();

  const { dictionary } = useContext(LanguageContext);
  const { setOrder, order, callback, addIdSt, setAddIdSt, setCallback } =
    useContext(MyContext);
  const [clientorders, setClientOrders] = useState();
  const [addIdS, setAddIdS] = useState(false);
  const [addIdD, setAddIdD] = useState(false);

  const params = useParams();
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    const getData = async () => {
      const result = await getClientOrders();
      setClientOrders(result);
    };
    getData();
  }, [callback]);

  useEffect(() => {
    clientorders?.forEach((item) => {
      if (item._id === params.id) {
        item.BookingDate = item.BookingDate?.slice(0, 10);
        setOrder(item);
      }
    });
  }, [clientorders, params.id, setOrder, reset]);

  const onSubmit = async (data) => {
    let result;
    if (addIdS) {
      result = await updateStartAddress(order._id, data);
    } else if (addIdSt) {
      result = await updateStopAddress(order._id, addIdSt, data);
    } else if (addIdD) {
      result = await updateDelAddress(order._id, data);
    } else {
      result = await addstopage(order._id, data);
    }
    setCallback(!callback)
    setOrder(result);
    reset({});
  };

  const clear = () => {
    setAddIdS(false);
    setAddIdD(false);
    setAddIdSt(null);
    reset({});
  };

  const handleStopage = async (stId) => {
    const res = await delStopage(order._id, stId);
    setOrder(res);
    setCallback(!callback)
    reset({});
  };

  
  const basePrice = order.PackageId?.PackagePrice;
  const KmPrice = (order.PackageId?.PricePerKm * order.Total_KM).toFixed(2) * 1;
  const StopagePrice =
    (order.PackageId?.StoppagePrice * order.StopagesAddress?.length).toFixed(
      2
    ) * 1;
  const loadingPrice = (order.Load_Unload / 5) * order.PackageId?.Price5min;
  const helpingPrice =
    (order.Load_Unload_Help / 60) * order.PackageId?.PricePerHelper;
  const Netto =
    (basePrice + KmPrice + StopagePrice + loadingPrice + helpingPrice).toFixed(
      2
    ) * 1;
  const Vat = (Netto * (order.PackageId?.MwSt / 100)).toFixed(2) * 1;
  const orderTotal = (Netto + Vat).toFixed(2) * 1;

  const goTo = () => {
    history.push("/client-dashboard");
  };

  return (
    <>
      <div className="client-order-details">
        <h1 className="job-board-title">
          {dictionary.client} ORDER DETAILS
        </h1>
        <div className="job-board-card mb-3">
          <div className="job-board-sub-title">
            <h1>Pending Jobs</h1>
            <button type="button" onClick={goTo}>
              {" "}
              <h1>Back</h1>{" "}
            </button>
          </div>
          <div className="client-order-details-body">
            <div className="order-details-client">
              <div className="ind-info">Booking ID: DE-{order.OrderNumber}</div>
              <div className="ind-info">Package: {order.PackageId?.Title}</div>
              <div className="ind-info">Booking Date: {order.BookingDate}</div>
              <div className="ind-info">Start Time: {order.BookingTime}</div>
              <div className="ind-info">Distance Price: {order.Total_KM} €</div>
              <div className="ind-info">
                Stopage: {order.StopagesAddress?.length}
              </div>
              <div className="ind-info">Total: {orderTotal} €</div>
              <div className="ind-info">Payment Type: {order.PaymentType}</div>
            </div>
            <h1>Order Address</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="delivery-addresses">
                <div className="all-Address">
                  <div className="address">
                    <h2>Start-Point</h2>
                    <br />
                    <h3>{order.StartAddress?.Name}</h3>
                    <p>
                      {order.StartAddress?.Street +
                        " " +
                        order.StartAddress?.HouseNo}
                    </p>
                    <p>
                      {order.StartAddress?.PostCode +
                        " " +
                        order.StartAddress?.City}
                    </p>
                    <p>{order.StartAddress?.Phone}</p>
                    <p>{order.StartAddress?.Comments}</p>
                    {/* <p>Last Update: {order.updatedAt?.slice(0, 16)}</p> */}
                    <div className="bill-address-button">
                      <button
                        type="button"
                        className="button-common px-5"
                        onClick={() => {
                          setAddIdS(true);
                          setAddIdD(false);
                          setAddIdSt(null);
                          reset(order.StartAddress);
                        }}
                      >
                        Edite
                      </button>
                    </div>
                  </div>
                  {order.StopagesAddress?.map((address, index) => {
                    // console.log(address);
                    return (
                      <div className="address" key={index}>
                        <h2>Stopage {index + 1}</h2>
                        <br />
                        <h3>{address.Name}</h3>
                        <p>{address.Street + " " + address.HouseNo}</p>
                        <p>{address.PostCode + " " + address.City}</p>
                        <p>{address.Phone}</p>
                        <p>{address.Comments}</p>
                        <div className="bill-address-button">
                          <button
                            type="button"
                            className="button-common px-5"
                            onClick={() => {
                              setAddIdS(false);
                              setAddIdD(false);
                              setAddIdSt(address._id);
                              reset(address);
                            }}
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            className="button-clear mx-3 px-5"
                            onClick={() => handleStopage(address._id)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    );
                  })}
                  <div className="address">
                    <h2>Delivery-Point</h2>
                    <br />
                    <h3>{order.DeliveryAddress?.Name}</h3>
                    <p>
                      {order.DeliveryAddress?.Street +
                        " " +
                        order.DeliveryAddress?.HouseNo}
                    </p>
                    <p>
                      {order.DeliveryAddress?.PostCode +
                        " " +
                        order.DeliveryAddress?.City}
                    </p>
                    <p>{order.DeliveryAddress?.Phone}</p>
                    <p>{order.DeliveryAddress?.Comments}</p>
                    <div className="bill-address-button">
                      <button
                        type="button"
                        className="button-common px-5"
                        onClick={() => {
                          setAddIdS(false);
                          setAddIdSt(null);
                          setAddIdD(true);
                          reset(order.DeliveryAddress);
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="addresses-form">
                {
                  addIdSt && <h1>Update Stopage Address</h1>
                  
                }
                {
                  addIdS && <h1>Update Start Address</h1>
                }
                {
                  addIdD && <h1>Update Delivery Address</h1>
                }
                {
                  (!addIdS && !addIdSt && !addIdD) && <h1>Add Stopage Address</h1>
                }
                
                <input
                  type="text"
                  placeholder="Full Name"
                  {...register("Name")}
                />
                <input
                  type="text"
                  placeholder="Street"
                  {...register("Street")}
                />
                <input placeholder="House Number" {...register("HouseNo")} />
                <input
                  type="input"
                  maxLength="5"
                  placeholder="PostCode"
                  {...register("PostCode")}
                />
                <input type="text" placeholder="City" {...register("City")} />
                <input
                  type="input"
                  maxLength="11"
                  placeholder="Mobile No"
                  {...register("Phone")}
                />
                <input
                  type="text"
                  placeholder="Comments"
                  {...register("Comments")}
                />
                <div className="submit">
                  <button
                    className="button-clear"
                    type="button"
                    onClick={clear}
                  >
                    Clear
                  </button>
                  <button className="button-common" type="submit">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default ClientOrders;
