import React, { useContext } from "react";
import PricingOne from "../components/PricingOne.jsx";
import { LanguageContext } from "../context/Languages.jsx";
import MyContext from "../context/Mycontext";

const Pricing = () => {
  const { dictionary } = useContext(LanguageContext);
  const { Packages } = useContext(MyContext);

  return (
    <>
      <div className="pricePageBody">
        <div className="pricing-main mt-2">
          <h1 className="title">-- {dictionary.packageprices} --</h1>
          <div className="body-row">
            {Packages?.map((item, index) => (
              <div key={index} className="row-col">
                <PricingOne
                  item={item}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
