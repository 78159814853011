import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import PricingTwo from "../components/PricingTwo.jsx";
import { LanguageContext } from "../context/Languages.jsx";
import YourBill from "../components/YourBill.jsx";
import BookService from "../components/BookService.jsx";
import MyContext from "../context/Mycontext";

const SearchRelocation = () => {
  const { userLanguage } = useContext(LanguageContext);
  const { Packages, selectPackage, setSelectPackage } = useContext(MyContext);

  const params = useParams();

  useEffect(() => {
    document.title = userLanguage === "en" ? "feruj" : "ahammed";
    if (params.id) {
      Packages.forEach((Package) => {
        if (Package._id === params.id) setSelectPackage(Package);
      });
    }
  }, [userLanguage, setSelectPackage, params.id, Packages]);

  if (selectPackage.length === 0) return null;

  return (
    <>
      <div className="search-PageBody">
        <h1 className="title">-- Book Now --</h1>
        <div className="search-Body">
          <div className="search-price-bill">
            <div className='price'>
              <PricingTwo item={selectPackage} />
            </div>
            <div className='bill'>
              <YourBill item={selectPackage} />
            </div>
          </div>
          <div>
            <BookService />
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchRelocation;
