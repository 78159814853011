import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { LanguageContext } from "../../context/Languages.jsx";
import MyContext from "../../context/Mycontext";
import { useForm } from "react-hook-form";
import { updateBillAddress } from "../../helpers/apiCalls";

const ClientDetails = () => {
  const { dictionary } = useContext(LanguageContext);
  const { client, setClient } = useContext(MyContext);
  const [btn, setBtn] = useState(false);
  const history = useHistory();
  const { register, handleSubmit, reset } = useForm({
    defaultValues: client,
  });

  useEffect(() => {
    reset(client.InvoiceAddress);
  }, [reset, client]);

  const onSubmit = async (data) => {
    const res = await updateBillAddress(client._id, data);
    setClient(res);
    history.push("/client-dashboard");
  };

  const goTo = () => {
    history.push("/client-dashboard");
  };

  return (
    <div className="client-details">
      <h1>{dictionary.client} DASHBOARD</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="client-info">
          <input
            type="text"
            placeholder="Company Name"
            defaultValue={client.CompanyName}
            disabled
          />
          <input
            type="text"
            placeholder="First Name"
            defaultValue={client.FirstName}
            disabled
          />
          <input
            type="text"
            placeholder="Last Name"
            defaultValue={client.LastName}
            disabled
          />
          <input
            type="text"
            placeholder="Phone"
            defaultValue={client.Phone}
            disabled
          />
          <input
            type="text"
            placeholder="Email"
            defaultValue={client.Email}
            disabled
          />
        </div>
        <div className="client-billAddress">
          <h2>Bill Address</h2>
          <input
            onClick={() => setBtn(true)}
            type="text"
            placeholder="Company Name"
            defaultValue={client.InvoiceAddress?.CompanyName}
            {...register("CompanyName")}
          />
          <input
            onClick={() => setBtn(true)}
            type="text"
            placeholder="Full Name"
            defaultValue={client.InvoiceAddress?.Name}
            {...register("Name")}
          />
          <input
            onClick={() => setBtn(true)}
            type="text"
            placeholder="Street"
            defaultValue={client.InvoiceAddress?.Street}
            {...register("Street")}
          />
          <input
            onClick={() => setBtn(true)}
            type="text"
            placeholder="House Number"
            defaultValue={client.InvoiceAddress?.HouseNumber}
            {...register("HouseNumber")}
          />
          <input
            onClick={() => setBtn(true)}
            type="input"
            maxLength="5"
            placeholder="Post Code"
            defaultValue={client.InvoiceAddress?.PostCode}
            {...register("PostCode")}
          />
          <input
            onClick={() => setBtn(true)}
            type="text"
            placeholder="City"
            defaultValue={client.InvoiceAddress?.City}
            {...register("City")}
          />
        </div>
        <div className="client-details-btn">
          {btn && <button className="button-common">Update</button>}
          <button type="button" className="button-common" onClick={goTo}>
            Back
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientDetails;
