import React, { useContext } from "react";
import MyContext from "../../context/Mycontext";
import { useForm } from "react-hook-form";
import { updatePackage, addPackage } from "../../helpers/apiCalls";

const CreatePackage = () => {
  const {
    Package,
    setPackage,
    services,
    Packages,
    packId,
    setPackId,
    callback,
    setCallback,
  } = useContext(MyContext);

  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (data) => {
    let res;
    if (packId) {
      res = await updatePackage(packId, data);
    } else {
      res = await addPackage(data);
    }
    setPackage(res.data);
    reset({});
    setCallback(!callback);
  };

  const clear = () => {
    setPackId(null);
    reset({});
    setCallback(!callback);
  };

  return (
    <div className="create-package">
      <h1>Create and Update Package</h1>
      <div className="package-page-body">
        <div className="all-packages">
          <h1>Package List</h1>
          <div className="package-list">
            {Packages?.map((item) => {
              return (
                <div className="packages" key={item._id}>
                  <pre>
                    <h2>Title : {item.Title}</h2>
                    <p>Title-D : {item.TitleDE}</p>
                    <p>Details-E : {item.BigDetails}</p>
                    <p>Details-D : {item.BigDetailsDE} </p>
                    <p>Price : {item.PackagePrice}</p>
                    <p>SortIndex : {item.SortIndex}</p>
                    <p>Status : {item.Status.toString()}</p>
                    <p>Pro KM : {item.PricePerKm} </p>
                    <p>Helper per Hour : {item.PricePerHelper}</p>
                    <p>MwSt %: {item.MwSt}</p>
                    <p>Every 5 Min : {item.Price5min}</p>
                    <p>Stopage : {item.StoppagePrice} </p>
                  </pre>
                  <div className="bill-address-button">
                    <button
                      type="button"
                      onClick={() => {
                        setPackId(item._id);
                        reset(item);
                      }}
                    >
                      Update
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <header>
            {
              packId ? <h1>Update Package</h1> : <h1>Create Package</h1>
            }
            
          </header>
          <input
            type="text"
            label="Title"
            placeholder="Title"
            defaultValue={Package.Title}
            {...register("Title")}
          />
          <input
            type="text"
            placeholder="TitleDe"
            defaultValue={Package.TitleDE}
            {...register("TitleDE")}
          />
          <textarea
            type="text"
            placeholder="BigDetails English"
            aria-multiline={true}
            rows="6"
            defaultValue={Package.BigDetails}
            {...register("BigDetails")}
          />

          <textarea
            type="text"
            placeholder="BigDetails German"
            rows="6"
            defaultValue={Package.BigDetailsDE}
            {...register("BigDetailsDE")}
          />

          <input
            type="number"
            placeholder="0.00"
            defaultValue={Package.PackagePrice}
            step="0.01"
            {...register("PackagePrice")}
          />
          <input
            type="number"
            placeholder="SortIndex"
            defaultValue={Package.SortIndex}
            {...register("SortIndex")}
          />
          <input
            type="text"
            placeholder="Status"
            defaultValue={Package.Status}
            {...register("Status")}
          />
          <input
            type="number"
            placeholder="PricePerKm"
            step="0.01"
            defaultValue={Package.PricePerKm}
            {...register("PricePerKm")}
          />
          <input
            type="number"
            placeholder="PricePerHelper"
            step="0.01"
            defaultValue={Package.PricePerHelper}
            {...register("PricePerHelper")}
          />
          <input
            type="number"
            placeholder="MwSt"
            step="0.01"
            defaultValue={Package.MwSt}
            {...register("MwSt")}
          />
          <input
            type="number"
            placeholder="Price5min"
            step="0.01"
            defaultValue={Package.Price5min}
            {...register("Price5min")}
          />
          <input
            type="number"
            placeholder="StoppagePrice"
            step="0.01"
            defaultValue={Package.StoppagePrice}
            {...register("StoppagePrice")}
          />
          <select {...register("ServiceID")}>
            {services.map((service) => (
              <option value={service._id} key={service._id}>
                {service.Title}
              </option>
            ))}
          </select>
          <button type="submit">Save</button>
          <button type="button" onClick={clear}>
            Clear
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreatePackage;
