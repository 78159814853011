import React, { useEffect, useContext, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import ServicesSearch from "../components/ServicesSearch.jsx";
import { API } from "../utils/index.jsx";
import { LanguageContext } from "../context/Languages.jsx";
import Slider from "./Slider";
import axios from "axios";
import qs from "qs";

const ServiceDetails = (props) => {
  const { userLanguage } = useContext(LanguageContext);
  const [data, setData] = useState({});


  useEffect(() => {
    document.title = userLanguage === "en" ? "a" : "b";

    function getServiceData() {
      let requestUrl = API.GET_SERVICES;
      const serviceId = qs.parse(document.location.search, {
        ignoreQueryPrefix: true,
      }).serviceId;

      requestUrl = requestUrl + "/" + serviceId;
      axios
        .get(`${requestUrl}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getServiceData();
  }, [userLanguage, data.serviceId]);

  return (
    <>
      <div className="service-details-main">
        <Container>
          <Row>
            <Col md={12} className="ml-auto mr-auto">
              <h1 className="text-center">
                -- {userLanguage === "en" ? data.Title : data.TitleDe} --
              </h1>
              <div className="service-details-slider">
                <Slider data={data.BigImage} />
              </div>
              {userLanguage === "en" ? (
                <div
                  className="service-details-detail"
                  dangerouslySetInnerHTML={{ __html: data.BigDetails }}
                />
              ) : (
                <div
                  className="service-details-detail"
                  dangerouslySetInnerHTML={{ __html: data.BigDetailsDe }}
                />
              )}
              {/* <Card className="service-details-detail">
                <CardText>
                  <pre>
                    {userLanguage === "en"
                      ? data.BigDetails
                      : data.BigDetailsDe}
                  </pre>
                </CardText>
              </Card> */}
            </Col>
          </Row>
        </Container>
        <ServicesSearch />
      </div>
    </>
  );
};

export default ServiceDetails;
