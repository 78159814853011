import React, { useContext, useEffect } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { LanguageContext } from "../../context/Languages.jsx";
import { Link } from "react-router-dom";
import MyContext from "../../context/Mycontext";
import { getPartnerOrders } from "../../helpers/apiCalls";

const PartnerJobs = () => {
  const { dictionary } = useContext(LanguageContext);
  const { setPartnerOrders, partnerOrders } = useContext(MyContext);
  // const history = useHistory();
  // const jobdetails = () => history.push("/job-details");

  useEffect(() => {
    const getData = async () => {
      let items = await getPartnerOrders();
      setPartnerOrders(items);
    };
    getData();
  }, [setPartnerOrders]);

  let acceptedOrders = [];
  let completedOrders = [];
  partnerOrders.map((order) => {
    if (order.OrderStatus === "Accept") {
      acceptedOrders.push(order);
    }
    if (order.OrderStatus === "Done") {
      completedOrders.push(order);
    }
    return order;
  });

  console.log(completedOrders);

  return (
    <>
      <div className="partner-jobs">
        <div className="title-field">
          <h1>MY JOB BOARD</h1>
        </div>

        <div className="general-job-table m-3 text-center">
          <h2 className="general-job-table-title">Accepted Jobs</h2>
          <Table>
            <Thead>
              <Tr>
                <Th>{dictionary.order} ID</Th>
                <Th>Package</Th>
                <Th>Date</Th>
                <Th>Time</Th>
                <Th>Details</Th>
                <Th>{dictionary.price}</Th>
              </Tr>
            </Thead>
            {acceptedOrders.map((order) => {
              return (
                <Tbody key={order._id}>
                  <Tr>
                    <Td>{order.OrderNumber}</Td>
                    <Td>{order.PackageId?.Title}</Td>
                    <Td>{order.BookingDate?.slice(0,10)}</Td>
                    <Td>{order.BookingTime}</Td>
                    <Td>
                      <Link
                        style={{ textDecoration: "none" }}
                        to={`/job-details/${order._id}`}
                      >
                        <span>Details</span>
                      </Link>
                    </Td>
                    <Td>Total</Td>
                  </Tr>
                </Tbody>
              );
            })}
          </Table>
        </div>
        <div className="general-job-table m-3 text-center">
          <h4 className="general-job-table-title">Completed Jobs</h4>
          <Table>
            <Thead>
              <Tr>
                <Th>{dictionary.order} ID</Th>
                <Th>Date</Th>
                <Th>Time</Th>
                <Th>Package</Th>
                <Th>Payment</Th>
                <Th>TOTAL</Th>
                <Th>Print</Th>
              </Tr>
            </Thead>
            {completedOrders.map((order) => {
              return (
                <Tbody key={order._id}>
                  <Tr>
                    <Td>{order.OrderNumber}</Td>
                    <Td>{order.BookingDate?.slice(0,10)}</Td>
                    <Td>{order.BookingTime}</Td>
                    <Td>{order.PackageId?.Title}</Td>
                    <Td>{order.PaymentType}</Td>
                    <Td>50 €</Td>
                    <Td>Download PDF</Td>
                  </Tr>
                </Tbody>
              );
            })}
          </Table>
        </div>
      </div>
    </>
  );
};
export default PartnerJobs;
