import * as EmailValidator from 'email-validator';

const PartnerRegistrationValidation = (val) => {
    let errs = {};
    let borderColors = {};
    const redData = "2px solid red";
    let success = false;
    let isValidationFailed = false;

    if (val.firstname.trim() === "") {
        // errs.firstname = "Required";
        borderColors.firstname = redData;
        isValidationFailed = true;
    } 
    if (val.familyname.trim() === "") {
        // errs.familyname = "Required";
        borderColors.familyname = redData;
        isValidationFailed = true;
    }
    if (val.mobilenumber.trim() === "") {
        // errs.mobilenumber = "Required";
        borderColors.mobilenumber = redData;
        isValidationFailed = true;
    } 
    if (val.street.trim() === "") {
        // errs.street = "Required";
        borderColors.street = redData;
        isValidationFailed = true;
    } 
    if (val.houseno.trim() === "") {
        // errs.houseno = "Required";
        borderColors.houseno = redData;
        isValidationFailed = true;
    } 
    if (val.zipcode.trim() === "") {
        // errs.zipcode = "Required";
        borderColors.zipcode = redData;
        isValidationFailed = true;
    } 
    if (val.nationality.trim() === "") {
        // errs.nationality = "Required";
        borderColors.nationality = redData;
        isValidationFailed = true;
    } 
    if (val.registeras.trim() === "") {
        // errs.registeras = "Required";
        borderColors.registeras = redData;
        isValidationFailed = true;
    } 
    if (val.email.trim() === "" || !EmailValidator.validate(val.email.trim())) {
        // errs.email = "Email error&include @";
        borderColors.email = redData;
        isValidationFailed = true;
    } 

    if (val.password.trim() === "") {
        // errs.password = "Required";
        borderColors.password = redData;
        isValidationFailed = true;
    } 

    if (val.repassword.trim() === "") {
        // errs.repassword = "Required";
        borderColors.repassword = redData;
        isValidationFailed = true;
    } 
    if (val.password.trim() !== "" && val.repassword.trim() !== "" && (val.password.trim() !== val.repassword.trim() || val.password.trim().length < 6)) {
        errs.password = "Password does not match.";
        errs.repassword = "Password does not match.";
        borderColors.password = redData;
        borderColors.repassword = redData;
        isValidationFailed = true;
    } 
    if (val.checked === false) {
        borderColors.checked = "red";
        isValidationFailed = true;
    } 
       
    if(!isValidationFailed) {
        success = true;
    }
    
    return {
        errs,
        borderColors,
        success
    };
}
export default PartnerRegistrationValidation;