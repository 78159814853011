const OrderValidation = (val) => {
    let borderColors = {};
    const redData = "2px solid red";
    let success = true;

    if (!val.Load_Unload) {
        borderColors.Load_Unload = redData;
        success = false;
    } 
    
    if (!val.Load_Unload_Help) {
        borderColors.Load_Unload_Help = redData;
        success = false;
    } 
    if (!val.ReciverName) {
        borderColors.ReciverName = redData;
        success = false;
    }    
    return {
        borderColors,
        success
    };

} 

export default OrderValidation;