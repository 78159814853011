import React, { useContext, useEffect } from "react";
import MyContext from "../../context/Mycontext";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import Loading from "../../components/Loading";
import { useForm } from "react-hook-form";
import "./Admin.css";

const CreateService = () => {
  const context = useContext(MyContext);
  const {
    isAdmin,
    services,
    service,
    setService,
    callback,
    setCallback,
    onEdit,
    setOnEdit,
    SmallImage,
    SetSmallImage,
    loading,
    setLoading,
  } = context;

  const { register, handleSubmit } = useForm({
    defaultValues: service,
  });
  const history = useHistory();
  const param = useParams();

  useEffect(() => {
    if (param.id) {
      setOnEdit(true);
      services.forEach((service) => {
        if (service._id === param.id) {
          setService(service);
          SetSmallImage(service.SmallImage);
        }
      });
    } else {
      setOnEdit(false);
      SetSmallImage([]);
    }
  }, [param.id, services, SetSmallImage, setOnEdit, setService]);

  const handleUpload = async (e) => {
    e.preventDefault();
    try {
      if (!isAdmin) return alert("You're not an admin");
      const files = e.target.files;
      let formData = new FormData();
      for (let file of files) {
        if (!file) return alert("File not exist.");
        if (file.size > 1024 * 1024) return alert("Size too large!");
        if (file.type !== "image/jpeg" && file.type !== "image/png")
          return alert("File format is incorrect.");
        formData.append("files", file);
      }
      setLoading(true);
      const res = await axios.post("/api/upload", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      setLoading(false);
      SetSmallImage(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDestroy = async (image) => {
    try {
      if (!isAdmin) return alert("You're not an admin");
      setLoading(true);
      await axios.post("/api/destroy", { public_id: image.public_id });
      setLoading(false);
      SetSmallImage(
        SmallImage.filter((item) => item.public_id !== image.public_id)
      );
    } catch (err) {
      alert(err.response.data.msg);
    }
  };

  const onSubmit = async (data) => {
    try {
      if (!isAdmin) return alert("You're not an admin");
      if (!SmallImage) return alert("No Image Upload");
      if (onEdit) {
        await axios.put(`api/services/${service._id}`, { ...data, SmallImage });
      } else {
        await axios.post("api/services", { ...data, SmallImage });
      }
      setCallback(!callback);
      history.push("/home");
    } catch (err) {
      alert(err.response.data.msg);
    }
  };

  const styleUpload = {
    display: SmallImage.length > 0 ? "block" : "none",
  };

  // console.log(`Before rendering the SmallImage`, SmallImage);
  const SmallImageList = SmallImage.map((image) => {
    return (
      <div key={image.public_id}>
        <img src={image.url} alt="" />
        <span onClick={() => handleDestroy(image)}>X</span>
      </div>
    );
  });

  return (
    <div className="create_services full-width-height">
      <div className="upload">
        <input
          type="file"
          name="file"
          id="file_upload"
          onChange={handleUpload}
          multiple
        />
        <label htmlFor="file_upload">+</label>
        <div className="image-list" style={styleUpload}>
          {loading ? <Loading /> : <>{SmallImageList}</>}
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="servicePage-field">
          <input
            type="number"
            placeholder="Service ID"
            defaultValue={service.ServiceID}
            {...register("ServiceID")}
          />
          <input
            type="number"
            placeholder="SortIndex"
            defaultValue={service.SortIndex}
            {...register("SortIndex")}
          />
          <input
            type="number"
            placeholder="Status"
            defaultValue={service.Status}
            {...register("Status")}
          />
          <input
            type="number"
            placeholder="isRelocationService"
            defaultValue={service.IsRelocationService}
            {...register("IsRelocationService")}
          />
          <input
            type="text"
            placeholder="Title English"
            defaultValue={service.Title}
            {...register("Title")}
          />
          <input
            type="text"
            placeholder="Title German"
            defaultValue={service.TitleDe}
            {...register("TitleDe")}
          />

          <textarea
            maxLength="250"
            type="text"
            placeholder="SmallDetails English"
            aria-multiline={true}
            rows="3"
            defaultValue={service.SmallDetails}
            {...register("SmallDetails")}
          />

          <textarea
            maxLength="250"
            type="text"
            placeholder="SmallDetails German"
            rows="3"
            defaultValue={service.SmallDetailsDe}
            {...register("SmallDetailsDe")}
          />

          <textarea
            type="text"
            rows="6"
            placeholder="Service Details English"
            defaultValue={service.BigDetails}
            {...register("BigDetails")}
          />

          <textarea
            type="text"
            rows="6"
            placeholder="Service Details German"
            defaultValue={service.BigDetailsDe}
            {...register("BigDetailsDe")}
          />
          <button type="submit">{onEdit ? "Update" : "Create"}</button>
        </div>
      </form>
    </div>
  );
};

export default CreateService;
