import React from 'react'

const ClientInfo = () => {
    return (
        <div className='full-width-height'>
            Client Details Info
        </div>
    )
}

export default ClientInfo
