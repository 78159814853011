import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import MyContext from "../../context/Mycontext";
import { partnerLogin } from "../../helpers/apiCalls.js";
import { useHistory } from "react-router-dom";

const PartnerLogin = () => {
  let history = useHistory();
  const { register, handleSubmit } = useForm();
  const context = useContext(MyContext);
  const { callback, setCallback, loading, setLoading, setPartner, setIsAdmin } =
    context;

  const onSubmit = async (data) => {
    setLoading(true);
    setTimeout(async () => {
      try {
        const result = await partnerLogin(data);
        if (result === 1) {
          history.push("/partner-registration");
          setLoading(false);
          return alert(
            "Email not found on our Partner List, Please Register.."
          );
        }
        if (result === 2) {
          setLoading(false);
          return alert("Password dosenot match, try again..");
        }
        if (result.Role === 1) {
          setIsAdmin(true);
          setLoading(false);
          setCallback(!callback)
          return history.push("/kurier247-admin-dashboard");
        }
        setPartner(result);
        setCallback(!callback)
        history.push("/partner-dashboard");
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }, 1000);
  };

  return (
    <>
      <div className="login-form-container">
        <h1 className="title">PARTNER LOGIN</h1>

        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="email"
            placeholder="Email"
            defaultValue="ferujahammed@gmail.com"
            {...register("Email")}
          />
          <input
            type="password"
            placeholder="Password"
            defaultValue="866505@Gmail"
            {...register("Password")}
          />
          <div className="login-button">
            <button>{loading ? "Logging in..." : "Login"}</button>
          </div>
        </form>
        <div className="login-footer">
          <div className="mr-3">
            <input
              className="mr-3"
              type="checkbox"
              name="check"
              id="stay-logged-in"
            />
            <label htmlFor="stay-logged-in">Stay Logged In</label>
          </div>
          <div className="mx-3">
            <a href="/">Forgot Password?</a>
          </div>
          <div className="ml-3">
            Don't have an account?{"  "}
            <Link to="/partner-registration">Partner registration</Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default PartnerLogin;
