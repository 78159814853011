import React, { useContext } from "react";
import { LanguageContext } from "../context/Languages.jsx";
// import image from '../'
import "../css/App.css";
import MyContext from "../context/Mycontext.js";

const YourBill = ({ item }) => {
  const { userLanguage } = useContext(LanguageContext);
const {stopage, km} = useContext(MyContext)


  const KmPrice = ((item.PricePerKm * km).toFixed(2)) * 1;
  const StopagePrice = ((item.StoppagePrice * stopage).toFixed(2)) * 1;
  let Netto = ((item.PackagePrice + KmPrice + StopagePrice).toFixed(2)) * 1;
  let Vat = ((Netto * (item.MwSt / 100)).toFixed(2)) * 1;
  let Total = ((Netto + Vat).toFixed(2)) * 1;

  return (
    <>
      <div className="bill-main">
        <div className="bill-title">
          <h1>Your Bill</h1>
        </div>
        <div className="bill-content">
          <div className="bill-content-field total">
            <span>Package :</span>
            <span>
              {userLanguage === "en" ? item.Title : item.TitleDE}
            </span>
          </div>
          <hr />

          <div className="bill-content-field">
            <span >Base price :</span>
            <span >{item.PackagePrice} €</span>
          </div>
          <hr />

          <div className="bill-content-field">
            <span >Distance Price(*km) :</span>
            <span >{KmPrice} €</span>
          </div>
          <hr />

          <div className="bill-content-field">
            <span>Stoppage price :</span>
            <span>{StopagePrice} €</span>
          </div>
          <hr />

          <div className="bill-content-field">
            <span>Net sum :</span>
            <span>{Netto} €</span>
          </div>
          <hr />

          <div className="bill-content-field">
            <span>Vat 19% :</span>
            <span>{Vat} €</span>
          </div>
          <hr />

          <div className="bill-content-field total">
            <span>Total :</span>
            <span>{Total} €</span>
          </div>
        </div>

          <div className="bill-type">
            <div className='p-3'>CASH ON DAY / INVOICE / SEPA </div>
            <img src="../assets/visa-master.png" alt="cards" />
          </div>
      </div>
    </>
  );
};
export default YourBill;
