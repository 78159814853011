const AddressFormValidation = (val) => {
    let errs = {};
    let borderColors = {};
    const redData = "2px solid red";
    let success = true;


    if (!val.Name) {
        // errs.Name = "Required";
        borderColors.Name = redData;
        success = false;
    } 
    
    if (!val.Street) {
        // errs.Street = "Required";
        borderColors.Street = redData;
        success = false;
    } 
    if (!val.HouseNo) {
        // errs.HouseNo = "Required";
        borderColors.HouseNo = redData;
        success = false;
    }
    if (!val.PostCode) {
        // errs.PostCode = "Required";
        borderColors.PostCode = redData;
        success = false;
    }
    if (!val.City) {
        // errs.City = "Required";
        borderColors.City = redData;
        success = false;
    }

    
    return {
        errs,
        borderColors,
        success
    };

} 

export default AddressFormValidation;