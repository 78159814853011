import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import MyContext from "../context/Mycontext";
import { GoogleLogin } from "react-google-login";
import {
  updateOrder,
  signUpClient,
  clientLogin,
  addBillAddress,
  googleLoginClient,
} from "../helpers/apiCalls";

const Checkout = () => {
  const history = useHistory();
  const params = useParams();
  const context = useContext(MyContext);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const {
    client,
    setClient,
    checkButtonDisplay,
    setcheckButtonDisplay,
    allOrders,
    setOrder,
    order,
  } = context;

  const { register, handleSubmit } = useForm({
    defaultValues: client,
  });

  useEffect(() => {
    if (params.id) {
      allOrders.forEach((item) => {
        if (item._id === params.id) setOrder(item);
      });
    }
  }, [allOrders, setOrder, params.id]);

  const onSubmit = async (data) => {
    if (showSignUp) {
      const newClient = await signUpClient(data);
      setClient(newClient);
    }
    if (showLogin) {
      const logClient = await clientLogin(data);
      setClient(logClient);
    }
    if (client) {
      const res = await addBillAddress(client._id, data);
      setClient(res);
    }
    setcheckButtonDisplay(false);
  };

  const responseGoogle = async (response) => {
    const { email, familyName, givenName, googleId, imageUrl } =
      response.profileObj;
    const data = {
      Email: email,
      googleId,
      FirstName: givenName,
      LastName: familyName,
      avatar: imageUrl,
    };
    let result = await googleLoginClient(data);
    if (result.error) {
      console.log(result.error);
    }
    setClient(result);
  };

  // console.log(client);

  const KmPrice = (order.PackageId?.PricePerKm * order.Total_KM).toFixed(2) * 1;
  const StopagePrice =
    (order.PackageId?.StoppagePrice * order.StopagesAddress?.length).toFixed(2) * 1;
  let Netto =
    (order.PackageId?.PackagePrice + KmPrice + StopagePrice).toFixed(2) * 1;
  let Vat = (Netto * (order.PackageId?.MwSt / 100)).toFixed(2) * 1;
  let total = (Netto + Vat).toFixed(2) * 1;

  const bookedcash = async (data) => {
    // console.log(data);
    try {
      await updateOrder(params.id, {
        ClientId: data,
        Total: total,
        PaymentType: "CASH",
      });
      history.push("/thanks");
    } catch (error) {
      console.log(error);
    }
  };

  const bookedcard = async (data) => {
    // console.log(data);
    try {
      await updateOrder(params.id, {
        ClientId: data,
        Total: total,
        PaymentType: "CARD",
      });
      history.push("/thanks");
    } catch (error) {
      console.log(error);
    }
  };

  const bookedsepa = async (data) => {
    // console.log(data);
    try {
      await updateOrder(params.id, {
        ClientId: data,
        Total: total,
        PaymentType: "SEPA",
      });
      history.push("/thanks");
    } catch (error) {
      console.log(error);
    }
  };

  const bookedinv = async (data) => {
    // console.log(data);
    try {
      await updateOrder(params.id, {
        ClientId: data,
        Total: total,
        PaymentType: "INVOICE",
      });
      history.push("/thanks");
    } catch (error) {
      console.log(error);
    }
  };

  const signupForm = () => {
    setShowSignUp(true);
    setShowLogin(false);
  };
  const loginForm = () => {
    setShowSignUp(false);
    setShowLogin(true);
  };

  return (
    <div className="checkout">
      <h1>Checkout page</h1>
      <div className="checkout-body">
        <div className="checkout-form">
          <h1> {client ? "BILL ADDRESS" : "CLIENT REGISTRATION"}</h1>
          {!client && (
            <div className="all-user-buttons">
              {!showSignUp && (
                <button
                  className="button-common"
                  type="button"
                  onClick={signupForm}
                >
                  Sign Up
                </button>
              )}
              {!showLogin && (
                <button
                  className="button-common"
                  type="button"
                  onClick={loginForm}
                >
                  Log In
                </button>
              )}
              <GoogleLogin
                clientId="884341565533-nu6t3be9dr6q0bbm8c3n8v754hbjcohh.apps.googleusercontent.com"
                buttonText="Log In"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
                className="button-common google"
              />
            </div>
          )}
          {client ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row">
                <input
                  onClick={() => setcheckButtonDisplay(true)}
                  type="text"
                  placeholder="Company Name"
                  defaultValue={client.InvoiceAddress?.CompanyName}
                  {...register("CompanyName")}
                />
                <input
                  onClick={() => setcheckButtonDisplay(true)}
                  type="text"
                  placeholder="Name *"
                  defaultValue={client.InvoiceAddress?.Name}
                  {...register("Name", {
                    required: true,
                  })}
                />
              </div>
              <div className="form-row">
                <input
                  type="text"
                  placeholder="Street Name *"
                  defaultValue={client.InvoiceAddress?.Street}
                  {...register("Street", {
                    required: true,
                  })}
                />
                <input
                  onClick={() => setcheckButtonDisplay(true)}
                  type="text"
                  placeholder="House Number *"
                  defaultValue={client.InvoiceAddress?.HouseNumber}
                  {...register("HouseNumber", {
                    required: true,
                  })}
                />
              </div>
              <div className="form-row">
                <input
                  onClick={() => setcheckButtonDisplay(true)}
                  type="input"
                  placeholder="Post Code *"
                  maxLength="5"
                  pattern="[+-]?\d+(?:[.,]\d+)?"
                  defaultValue={client.InvoiceAddress?.PostCode}
                  {...register("PostCode", {
                    required: true,
                  })}
                />
                <input
                  onClick={() => setcheckButtonDisplay(true)}
                  type="text"
                  placeholder="City *"
                  defaultValue={client.InvoiceAddress?.City}
                  {...register("City", {
                    required: true,
                  })}
                />
              </div>
              {checkButtonDisplay && <button type="submit">Next</button>}
            </form>
          ) : (
            <>
              {showSignUp && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-row">
                    <input
                      type="text"
                      placeholder="First Name *"
                      {...register("FirstName", {
                        required: true,
                      })}
                    />
                    <input
                      type="text"
                      placeholder="Last Name *"
                      {...register("LastName", {
                        required: true,
                      })}
                    />
                  </div>
                  <div className="form-row">
                    <input
                      type="input"
                      placeholder="Phone *"
                      {...register("Phone", {
                        required: true,
                      })}
                    />
                  </div>
                  <div className="form-row">
                    <input
                      type="email"
                      placeholder="Email *"
                      {...register("Email", {
                        required: true,
                      })}
                    />
                    <input
                      type="password"
                      placeholder="Password *"
                      {...register("Password", {
                        required: true,
                      })}
                    />
                  </div>
                  {checkButtonDisplay && (
                    <button type="submit">Register</button>
                  )}
                </form>
              )}
              {showLogin && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-row">
                    <input
                      type="email"
                      placeholder="Email *"
                      {...register("Email", {
                        required: true,
                      })}
                    />
                    <input
                      type="password"
                      placeholder="Password *"
                      {...register("Password", {
                        required: true,
                      })}
                    />
                  </div>
                  {checkButtonDisplay && (
                    <button type="submit">
                      {" "}
                      {showSignUp ? "Register" : "Login"}{" "}
                    </button>
                  )}
                </form>
              )}
            </>
          )}
        </div>
        <div className="title">
          <h1>Your Bill</h1>
          <div className="checkout-bill">
            <div className="line">
              <h2>Package :</h2>
              <h2> {order.PackageId?.Title} </h2>
            </div>
            <div className="line">
              <div>Date :</div>
              <p> {order.BookingDate?.slice(0, 10)}</p>
            </div>
            <div className="line">
              <div>Time :</div>
              <p> {order.BookingTime}</p>
            </div>
            <div className="line">
              <div>Base Price :</div>
              <p> {order.PackageId?.PackagePrice} €</p>
            </div>
            <div className="line">
              <div>Distance Price :</div>
              <p> {KmPrice} €</p>
            </div>
            <div className="line">
              <div>Stopage Price :</div>
              <p> {StopagePrice} €</p>
            </div>
            <div className="line">
              <div>Netto :</div>
              <p> {Netto} €</p>
            </div>
            <div className="line">
              <div>Vat :</div>
              <p> {Vat} €</p>
            </div>
            <div className="line">
              <h2>Total :</h2>
              <h2> {total} €</h2>
            </div>
          </div>
        </div>
      </div>
      {!checkButtonDisplay && (
        <div className="checkout-payment">
          {client && (
            <>
              <button onClick={() => bookedcash(client._id)}>
                CASH PAYMENT
              </button>
              <button
                onClick={() => bookedcard(client._id)}
              >
                CREDIT CARD
              </button>
              <button
                onClick={() => bookedsepa(client._id)}
              >
                SEPA PAYMENT
              </button>
              {client.InvoiceAddress?.CompanyName && (
                <button
                onClick={() => bookedinv(client._id)}
                >
                  ON INVOICE
                </button>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Checkout;
