import {useContext} from "react";
import { Redirect, Route } from 'react-router-dom';
import MyContext from "../../context/Mycontext";
import Loading from '../../components/Loading';

const ClientPrivateRoute = ({ path, component, redirectTo = '/client-login' }) => {
  const context = useContext(MyContext);
  const { client, authIsDone} = context;
  if (!authIsDone) return <Loading />;
  if (authIsDone) {
    // setUniRoute(path)
    return client ? (
      <Route path={path} component={component} />
    ) : (
      <Redirect to={redirectTo} />
    );
  }
};

export default ClientPrivateRoute;
