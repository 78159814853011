import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Slider from "./Slider";
import { Card, CardBody, CardTitle, CardText } from "reactstrap";
import { LanguageContext } from "../context/Languages.jsx";
import MyContext from "../context/Mycontext";

const ServiceOne = (props) => {
  const { dictionary, userLanguage } = useContext(LanguageContext);
  const { isAdmin } = useContext(MyContext);

  return (
    <>
      <div className="service-one">
        <Card>
          <Slider data={props.imgS} />
          <CardBody style={{ height: "230px" }}>
            <CardTitle>
              {userLanguage === "en" ? props.title : props.title_De}{" "}
            </CardTitle>
            {/* <CardText className="text-left" dangerouslySetInnerHTML={{ __html: props.contentS_De}}/> */}
            {userLanguage === "en" ? (
              <CardText
                className="text-left"
                dangerouslySetInnerHTML={{ __html: props.contentS }}
              />
            ) : (
              <CardText
                className="text-left"
                dangerouslySetInnerHTML={{ __html: props.contentS_De }}
              />
            )}
            {isAdmin ? (
              <Link to={`/update-service/${props.id}`}>
                <button className='button-common'>EDIT</button>
              </Link>
            ) : (
              <Link
                to={`service-details?serviceId=${props.id}&serviceTitle=${
                  userLanguage === "en" ? props.title : props.title_De
                }`}
              >
                <button className='button-common'>{dictionary.knowmore}</button>
              </Link>
            )}
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default ServiceOne;
