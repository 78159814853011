import React, { useContext, useState } from "react";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { LanguageContext } from "../../context/Languages.jsx";
import { language, interest } from "../../utils/index.jsx";
import PartnerRegistrationValidation from "../../validation/PartnerRegisterOneValidation";
import countryList from "react-select-country-list";
import { signUpPartner } from "../../helpers/apiCalls";
import MyContext from "../../context/Mycontext";

const languageOptions = language;
const interestOptions = interest;
const countries = countryList().getData();

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    borderRadius: "0px",
  }),
  option: (styles) => {
    const color = "#white";
    return {
      ...styles,
      backgroundColor: color,
      color: "#111",
    };
  },
  multiValue: (styles) => {
    //   const color = "red";
    return {
      ...styles,
      backgroundColor: "white",
      color: "#111",
      border: "1px solid #111",
    };
  },
  multiValueLabel: (styles, { data }) => {
    const color = "#white";
    return {
      ...styles,
      color: color,
    };
  },
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "#111",
    },
  }),
};

const PartnerRegistrationOne = (props) => {
  const { dictionary } = useContext(LanguageContext);
  const { setPartner, setAuthIsDone } = useContext(MyContext);
  const history = useHistory();

  /* Form handler start */

  const [formVal, setFormVal] = useState({
    mobilenumber: "",
    salutation: "Mr.",
    firstname: "",
    familyname: "",
    street: "",
    houseno: "",
    zipcode: "",
    city: "",
    country: "",
    nationality: "",
    birthday: "",
    idpassport: "",
    validity: "",
    interestedwork: [],
    registeras: "",
    email: "",
    password: "",
    repassword: "",
    around: "",
    inzipcode: "",
    languages: [],
    checked: false,
  });
  const [checkedData, setCheckedData] = useState({});

  const formHandler = (e) => {
    e.preventDefault();
    const id = e.target.id;
    const val = e.target.value;
    setFormVal({
      ...formVal,
      [id]: val,
    });
    setCheckedData({});
  };

  const checkboxHandler = () => {
    setFormVal({
      ...formVal,
      checked: !formVal.checked,
    });
  };

  const formNumberHandler = (e) => {
    e.preventDefault();
    const id = e.target.id;
    const val = e.target.value;
    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
    if (rx_live.test(val)) {
      setFormVal({
        ...formVal,
        [id]: val,
      });
    }
    setCheckedData({});
  };

  const registratione = async () => {
    let checkedData = PartnerRegistrationValidation(formVal);
    setCheckedData(checkedData);
    if (checkedData.success) {
      let partner = {
        Salutation: formVal.salutation,
        FirstName: formVal.firstname,
        FamilyName: formVal.familyname,
        MobileNumber: formVal.mobilenumber,
        Street: formVal.street,
        HouseNumber: formVal.houseno,
        PostCode: formVal.zipcode,
        City: formVal.city,
        Country: formVal.country,
        InterestedArea: formVal.inzipcode,
        Nationality: formVal.nationality,
        DOB: formVal.birthday,
        IDorPassportNo: formVal.idpassport,
        PassportValidity: formVal.validity,
        Interested: formVal.interestedwork,
        RegisterAs: formVal.registeras,
        ChooseKm: formVal.around,
        languageskill: formVal.languages,
        Email: formVal.email,
        Password: formVal.password,
      };
      console.log("partner data..........", partner);

      try {
        const newPartner = await signUpPartner(partner);
        if (newPartner === 1) {
          history.push("/partner-login");
          return alert("User Email exists please Login");
        }
        setPartner(newPartner);
        setAuthIsDone(true);
        history.push(`/partner-registration-two/${newPartner._id}`);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const lanHandler = (val) => {
    var lanVal = "";
    if (val.length > 0) {
      val.map((item, index) => {
        lanVal += item.label + ",";
        return lanVal;
      });
    }
    setFormVal({
      ...formVal,
      languages: lanVal,
    });
  };

  /* Form handler end */

  return (
    <>
      <div className="registration-page">
        <div className="container">
          <div className="body">
            <h1>-- Partner {dictionary.registrationTitle} --</h1>
          </div>
          <div className="reg-form-row">
            <div className="row-col">
              <select
                name="salutation"
                id="salutation"
                value={formVal.salutation}
                onChange={(e) => formHandler(e)}
              >
                <option>Mr.</option>
                <option>Ms.</option>
              </select>
            </div>
            <div className="row-col">
              <input
                type="text"
                name="firstname"
                id="firstname"
                placeholder="First Name"
                required
                value={formVal.firstname}
                onChange={(e) => formHandler(e)}
                style={
                  checkedData.borderColors !== undefined
                    ? checkedData.borderColors.firstname
                      ? { border: checkedData.borderColors.firstname }
                      : {}
                    : {}
                }
              />
            </div>
          </div>
          <div className="reg-form-row">
            <div className="row-col">
              <input
                type="text"
                name="familyname"
                id="familyname"
                placeholder={dictionary.familyname}
                required
                value={formVal.familyname}
                onChange={(e) => formHandler(e)}
                style={
                  checkedData.borderColors !== undefined
                    ? checkedData.borderColors.familyname
                      ? { border: checkedData.borderColors.familyname }
                      : {}
                    : {}
                }
              />
            </div>
            <div className="row-col">
              <input
                type="input"
                name="mobilenumber"
                id="mobilenumber"
                pattern="[+-]?\d+(?:[.,]\d+)?"
                placeholder="Mobile Number"
                value={formVal.mobilenumber}
                onChange={(e) => formNumberHandler(e)}
                maxLength="11"
                required
                style={
                  checkedData.borderColors !== undefined
                    ? checkedData.borderColors.mobilenumber
                      ? { border: checkedData.borderColors.mobilenumber }
                      : {}
                    : {}
                }
              />
            </div>
          </div>
          <div className="reg-form-row">
            <div className="row-col">
              <input
                type="text"
                name="street"
                id="street"
                placeholder="Street"
                value={formVal.street}
                onChange={(e) => formHandler(e)}
                style={
                  checkedData.borderColors !== undefined
                    ? checkedData.borderColors.street
                      ? { border: checkedData.borderColors.street }
                      : {}
                    : {}
                }
              />
            </div>
            <div className="row-col">
              <input
                type="text"
                name="houseno"
                id="houseno"
                placeholder="House-No."
                value={formVal.houseno}
                onChange={(e) => formHandler(e)}
                required
                style={
                  checkedData.borderColors !== undefined
                    ? checkedData.borderColors.houseno
                      ? { border: checkedData.borderColors.houseno }
                      : {}
                    : {}
                }
              />
            </div>
          </div>
          <div className="reg-form-row">
            <div className="row-col">
              <input
                type="input"
                name="zipcode"
                id="zipcode"
                pattern="[+-]?\d+(?:[.,]\d+)?"
                placeholder="Post Code"
                value={formVal.zipcode}
                onChange={(e) => formNumberHandler(e)}
                maxLength="5"
                style={
                  checkedData.borderColors !== undefined
                    ? checkedData.borderColors.zipcode
                      ? { border: checkedData.borderColors.zipcode }
                      : {}
                    : {}
                }
              />
            </div>
            <div className="row-col">
              <input
                type="text"
                name="city"
                id="city"
                placeholder="City"
                value={formVal.city}
                onChange={(e) => formHandler(e)}
              ></input>
            </div>
          </div>
          <div className="reg-form-row">
            <div className="row-col">
              <select
                type="select"
                name="country"
                id="country"
                value={formVal.country}
                onChange={(e) => formHandler(e)}
              >
                <option value="">Select Country</option>
                <option value="Germany">Germany</option>
              </select>
            </div>
            <div className="row-col">
              <input
                type="text"
                name="inzipcode"
                id="inzipcode"
                placeholder="Interested in Area(Zip Code)"
                value={formVal.inzipcode}
                onChange={(e) => formHandler(e)}
              ></input>
            </div>
          </div>
          <div className="reg-form-row">
            <div className="row-col">
              <select
                type="select"
                name="nationality"
                id="nationality"
                value={formVal.nationality}
                onChange={(e) => formHandler(e)}
                style={
                  checkedData.borderColors !== undefined
                    ? checkedData.borderColors.nationality
                      ? { border: checkedData.borderColors.nationality }
                      : {}
                    : {}
                }
              >
                <option value="">Select Country</option>
                {countries.map((country, countryIndex) => (
                  <option value={country.label} key={countryIndex}>
                    {country.label}
                  </option>
                ))}
              </select>
              <div className="form-err text-left">
                {checkedData.errs !== undefined
                  ? checkedData.errs.nationality
                    ? checkedData.errs.nationality
                    : ""
                  : ""}
              </div>
            </div>
            <div className="row-col">
              <input
                type="date"
                name="birthday"
                id="birthday"
                placeholder="Birth Day"
                value={formVal.birthday}
                onChange={(e) => formHandler(e)}
              />
            </div>
          </div>
          <div className="reg-form-row">
            <div className="row-col">
              <input
                type="text"
                name="idpassport"
                id="idpassport"
                placeholder="Id or passport no."
                value={formVal.idpassport}
                onChange={(e) => formHandler(e)}
              ></input>
            </div>
            <div className="row-col">
              <input
                type="date"
                name="validity"
                id="validity"
                value={formVal.validity}
                onChange={(e) => formHandler(e)}
              ></input>
            </div>
          </div>
          <div className="reg-form-row">
            <div className="row-col">
              <select
                type="select"
                name="around"
                id="around"
                value={formVal.around}
                onChange={(e) => formHandler(e)}
              >
                <option value="0">Select</option>
                <option value="5">5 km</option>
                <option value="10">10 km</option>
                <option value="15">15 km</option>
                <option value="20">20 km</option>
                <option value="25">25 km</option>
                <option value="30">30 km</option>
                <option value="35">35 km</option>
                <option value="40">40 km</option>
                <option value="45">45 km</option>
                <option value="50">50 km</option>
              </select>
            </div>

            <div className="row-col">
              <select
                type="select"
                name="registeras"
                id="registeras"
                value={formVal.registeras}
                onChange={(e) => formHandler(e)}
                style={
                  checkedData.borderColors !== undefined
                    ? checkedData.borderColors.registeras
                      ? { border: checkedData.borderColors.registeras }
                      : {}
                    : {}
                }
              >
                <option value="">Select Area</option>
                <option value="Self Employee">{dictionary.selfEmployed}</option>
                <option value="Worker">{dictionary.Worker}</option>
              </select>
              <div className="form-err text-left">
                {checkedData.errs !== undefined
                  ? checkedData.errs.registeras
                    ? checkedData.errs.registeras
                    : ""
                  : ""}
              </div>
            </div>
          </div>
          <div className="reg-form-row">
            <div className="row-col">
              <Select
                defaultValue={[]}
                isMulti
                name="interestedwork"
                options={interestOptions}
                classNamePrefix="select"
                styles={colourStyles}
                onChange={(value) => lanHandler(value)}
              />
            </div>
            <div className="row-col">
              <Select
                defaultValue={[]}
                isMulti
                name="colors"
                options={languageOptions}
                classNamePrefix="select"
                styles={colourStyles}
                onChange={(value) => lanHandler(value)}
              />
            </div>
          </div>
          <div className="reg-form-row">
            <div className="row-col">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="E-mail"
                value={formVal.email}
                onChange={(e) => formHandler(e)}
                style={
                  checkedData.borderColors !== undefined
                    ? checkedData.borderColors.email
                      ? { border: checkedData.borderColors.email }
                      : {}
                    : {}
                }
              ></input>
              <div className="form-err text-left">
                {checkedData.errs !== undefined
                  ? checkedData.errs.email
                    ? checkedData.errs.email
                    : ""
                  : ""}
              </div>
            </div>
            <div className="row-col">
              <input
                type="password"
                name="password"
                placeholder="Password"
                id="password"
                value={formVal.password}
                onChange={(e) => formHandler(e)}
                style={
                  checkedData.borderColors !== undefined
                    ? checkedData.borderColors.password
                      ? { border: checkedData.borderColors.password }
                      : {}
                    : {}
                }
              />
            </div>
          </div>
          <div className="reg-form-row">
            <div className="row-col">
              <input
                type="password"
                name="repassword"
                placeholder="Retype Password"
                id="repassword"
                value={formVal.repassword}
                onChange={(e) => formHandler(e)}
                style={
                  checkedData.borderColors !== undefined
                    ? checkedData.borderColors.repassword
                      ? { border: checkedData.borderColors.repassword }
                      : {}
                    : {}
                }
              />
            </div>
          </div>
          <div className="reg-form-terms">
            <input
              type="checkbox"
              name="check"
              id="terms_conditions"
              onChange={checkboxHandler}
              style={
                checkedData.borderColors !== undefined
                  ? checkedData.borderColors.checked
                    ? { color: checkedData.borderColors.checked }
                    : {}
                  : {}
              }
            />
            <label htmlFor="terms_conditions">
              {dictionary.termsAccept}
              <a
                href="https://kurier247.de/privacy-policy.aspx"
                className="content"
                style={
                  checkedData.borderColors !== undefined
                    ? checkedData.borderColors.checked
                      ? { color: checkedData.borderColors.checked }
                      : {}
                    : {}
                }
              >
                {" "}
                {dictionary.termscondition}
              </a>
            </label>
          </div>
        </div>
        <div className="form-button">
          <button
            type="submit"
            color="success"
            id="register-one"
            onClick={() => registratione()}
          >
            {dictionary.submitContinue}
          </button>
        </div>
      </div>
    </>
  );
};
export default PartnerRegistrationOne;
