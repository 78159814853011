import React, { useContext } from "react";
import { LanguageContext } from "../context/Languages.jsx";

const ServicesSearch = () => {
  const { dictionary } = useContext(LanguageContext);

  return (
    <>
      <div className="book-service text-center">
        <h1>{dictionary.bookservice}</h1>
        <h2>Quick and Easy</h2>
        <div className="search-service-form-group">
          <input placeholder="ZIP Code..." />
          <button>SEARCH NOW</button>
        </div>
      </div>
    </>
  );
};

export default ServicesSearch;
