import React, { useContext, useState } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  FormText,
} from "reactstrap";
import { LanguageContext } from "../../context/Languages";
import { updatePartner } from "../../helpers/apiCalls";
import MyContext from "../../context/Mycontext";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";

const PartnerRegistrationTwo = () => {
  const params = useParams();
  const { dictionary } = useContext(LanguageContext);
  const history = useHistory();

  const { setPartner, setAuthIsDone, partner, setLoading } =
    useContext(MyContext);

  const [file, setFile] = useState("");
  const [filename, setFilename] = useState("Choose File");
  const [uploadedFile, setUploadedFile] = useState({});

  const onChange = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  /*--Form start--*/
  const [formVal, setFormVal] = useState({
    idPassportFront: "",
    idPassportBack: "",
    gewerbeAnmeldung: "",
    policeCertificate: "",
    profilePhoto: "",
    idNumber: "",
    taxId: "",
    bankIban: "",
    checked: false,
  });
  const [checkedData, setCheckedData] = useState({});

  const formHandler = (e) => {
    e.preventDefault();
    const id = e.target.id;
    const val = e.target.value;
    setFormVal({
      ...formVal,
      [id]: val,
    });
    setCheckedData({});
  };
  const checkboxHandler = () => {
    setFormVal({
      ...formVal,
      checked: !formVal.checked,
    });
  };
  const send = async () => {
    let matchedDatas = {
      IdentificationNumber: formVal.idNumber,
      BankIBAN: formVal.bankIban,
      TaxId: formVal.taxId,
      IdPassportFront: formVal.idPassportFront,
      IdPassportBack: formVal.idPassportBack,
      BusinessRegistration: formVal.gewerbeAnmeldung,
      PoliceClearence: formVal.policeCertificate,
      ProfilePic: formVal.profilePhoto,
    };
    console.log(matchedDatas);

    try {
      const res = await updatePartner(params.id, matchedDatas);
      console.log(res);
      setPartner(res);
      setAuthIsDone(true);
      // history.push("/partner-dashboard");
      return alert(
        "We Recive your Registration details, Our team will check your profile and Update you with further,step. thanks"
      );
    } catch (error) {
      console.log(error);
    }
  };

  /*--Form end--*/

  return (
    <>
      <div className="full-width-height">
        <Container>
          <Card className="form mb-4 login-form-body">
            <div className="title-field">
              <h1 className="title">
                -- Partner {dictionary.registrationTitle} --
              </h1>
            </div>
            <Row className="mt-4">
              <Col md={6}>
                <FormGroup>
                  <Label for="idPassportFront">
                    {dictionary["id/pass"]} {dictionary.front}
                  </Label>
                  <div className="id_passport">
                    <Input
                      type="file"
                      name="idPassportFront"
                      id="idPassportFront"
                      // onChange={(e) => formHandler(e) }
                      onChange={onChange}
                      value={formVal.idPassportFront}
                    />
                  </div>
                  <FormText color="muted">
                    File type : JPG, JPEG, GIF , PNG, PDF, DOC , DOCX. Maximum
                    file size: 4MB
                  </FormText>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="idPassportBack">
                    {dictionary["id/pass"]} {dictionary.back}
                  </Label>
                  <div className="id_passport">
                    <Input
                      type="file"
                      name="idPassportBack"
                      id="idPassportBack"
                      onChange={(e) => formHandler(e)}
                      value={formVal.idPassportBack}
                    />
                  </div>
                  <FormText color="muted">
                    File type : JPG, JPEG, GIF , PNG, PDF, DOC , DOCX. Maximum
                    file size: 4MB
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={6}>
                <FormGroup>
                  <Label for="gewerbeAnmeldung">
                    Gewerbe Anmeldung (for Self Employed)
                  </Label>
                  <div className="id_passport">
                    <Input
                      type="file"
                      name="gewerbeAnmeldung"
                      id="gewerbeAnmeldung"
                      onChange={(e) => formHandler(e)}
                      value={formVal.gewerbeAnmeldung}
                    />
                  </div>
                  <FormText color="muted">
                    File type : JPG, JPEG, GIF , PNG, PDF, DOC , DOCX. Maximum
                    file size: 4MB
                  </FormText>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="policeClearanceCertificate">
                    {dictionary.policeCertificate}
                  </Label>
                  <div className="id_passport">
                    <Input
                      type="file"
                      name="policeCertificate"
                      id="policeCertificate"
                      onChange={(e) => formHandler(e)}
                      value={formVal.policeCertificate}
                    />
                  </div>
                  <FormText color="muted">
                    File type : JPG, JPEG, GIF , PNG, PDF, DOC , DOCX. Maximum
                    file size: 4MB
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={6}>
                <FormGroup>
                  <Label for="idNumber">
                    Identification {dictionary.number}*
                  </Label>
                  <Input
                    type="text"
                    name="idNumber"
                    id="idNumber"
                    placeholder=""
                    onChange={(e) => formHandler(e)}
                    value={formVal.idNumber}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="taxId">{dictionary.taxID}*</Label>
                  <Input
                    type="text"
                    name="taxId"
                    id="taxId"
                    placeholder=""
                    onChange={(e) => formHandler(e)}
                    value={formVal.taxId}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={6}>
                <FormGroup>
                  <Label for="profilePhoto">Profile Photo</Label>
                  <div className="id_passport">
                    <Input
                      type="file"
                      name="profilePhoto"
                      id="profilePhoto"
                      onChange={(e) => formHandler(e)}
                      value={formVal.profilePhoto}
                    />
                  </div>
                  <FormText color="muted">
                    File type : JPG, JPEG, GIF , PNG, PDF, DOC , DOCX. Maximum
                    file size: 4MB
                  </FormText>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="bankIban">Bank IBAN*</Label>
                  <Input
                    type="text"
                    name="bankIban"
                    id="bankIban"
                    placeholder="Bank IBAN*"
                    onChange={(e) => formHandler(e)}
                    value={formVal.bankIban}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={12}>
                <FormGroup check>
                  <Input
                    type="checkbox"
                    name="terms_conditions_agreement"
                    id="terms_conditions_agreement"
                    onChange={checkboxHandler}
                  />
                  <div
                    className="terms-conditions"
                    style={
                      checkedData.borderColors !== undefined
                        ? checkedData.borderColors.checked
                          ? { color: checkedData.borderColors.checked }
                          : {}
                        : {}
                    }
                  >
                    <Label for="terms_conditions_agreement" check>
                      {dictionary.iConfirm}
                      <a
                        href="https://kurier247.de/privacy-policy.aspx"
                        className="content"
                        style={
                          checkedData.borderColors !== undefined
                            ? checkedData.borderColors.checked
                              ? { color: checkedData.borderColors.checked }
                              : {}
                            : {}
                        }
                      >
                        {" "}
                        {dictionary.termscondition}
                      </a>
                    </Label>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={12}>
                <FormGroup>
                  <Button
                    type="submit"
                    id="login"
                    color="success"
                    onClick={() => send()}
                  >
                    {dictionary.send}
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default PartnerRegistrationTwo;
