import React, { useContext } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { LanguageContext } from "../context/Languages";
import { Link } from "react-router-dom";
import { LanguageSelector } from "../components/LanguageSelector.jsx";

const MobileMenu = ({ positionRight }) => {
  /*-- Multiple language option -- */
  const { dictionary } = useContext(LanguageContext);
  return (
    <div
      className="navbar-content-two"
      style={{ right: `${positionRight}px`, backgroundColor: "#b2b7da" }}
    >
      <ListGroup>
        <ListGroupItem className="bg-color">
          <Link to="/pricing">{dictionary.price}</Link>
        </ListGroupItem>
        <ListGroupItem className="bg-color">
          <Link to="/partner-login">PARTNER LOGIN</Link>
        </ListGroupItem>
        <ListGroupItem className="bg-color">
          <Link to="/client-login">{dictionary.client} LOGIN</Link>
        </ListGroupItem>
        <ListGroupItem className="bg-color">
          <LanguageSelector />
        </ListGroupItem>
      </ListGroup>
    </div>
  );
};

export default MobileMenu;
