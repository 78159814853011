import React, { useState, useEffect } from "react";
import MyContext from "./Mycontext";
import {
  authenticatePartner,
  authenticateClient,
  getServices,
  getPackages,
  getOrders,
  getClients,
  getPartners,
} from "../helpers/apiCalls";

const Myprovider = (props) => {
  const [clients, setClients] = useState([]);
  const [clientOrders, setClientOrders] = useState([]);
  const [clientInvoices, setClientInvoices] = useState([]);
  const [partners, setPartners] = useState([]);
  const [partnerOrders, setPartnerOrders] = useState([]);
  const [partnerInvoices, setPartnerInvoices] = useState([]);
  const [services, setServices] = useState([]);
  const [Packages, setPackages] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [allInvoices, setAllInvoices] = useState([]);
  const [SmallImage, SetSmallImage] = useState([]);
  const [BigImage, setBigImage] = useState([]);
  const [selectPackage, setSelectPackage] = useState([]);
  const [stopageData, setStopageData] = useState([]);

  const [client, setClient] = useState({});
  const [partner, setPartner] = useState({});
  const [checkedData, setCheckedData] = useState({});
  const [aboutUsData, setAboutUsData] = useState({});
  const [service, setService] = useState({});
  const [Package, setPackage] = useState({});
  const [order, setOrder] = useState({});
  const [invoice, setInvoice] = useState({});
  const [pickupData, setPickupData] = useState({});
  const [deliveryData, setDeliveryData] = useState({});
  const [tempOrder, setTempOrder] = useState({});

  const [error, setError] = useState("");
  const [partnerLoggedIn, setPartnerLoggedIn] = useState(false);
  const [clientLoggedIn, setClientLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [authIsDone, setAuthIsDone] = useState(false);
  const [onEdit, setOnEdit] = useState(false);
  const [callback, setCallback] = useState(false);
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [packId, setPackId] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [stopage, setStopage] = useState(0);
  const [km, setKm] = useState(1);
  const [bookingDate, setBookingDate] = useState(new Date());
  const [bookingTime, setBookingTime] = useState({ BookingTime: "" });
  const [stoppageBtn, setStoppageBtn] = useState(false);
  const [buttonDisplay, setButtonDisplay] = useState(true);
  const [checkButtonDisplay, setcheckButtonDisplay] = useState(true);
  const [values, setValues] = useState("");
  const [ids, setIds] = useState("");
  const [names, setNames] = useState("");
  const [delAddId, setDelAddId] = useState(null);
  const [businessAddress, setBusinessAddress] = useState("");
  const [uniRoute, setUniRoute] = useState("/");
  const [addIdSt, setAddIdSt] = useState(null);

  useEffect(() => {
    const authMe = async () => {
      try {
        const result = await authenticatePartner();
        // console.log(result);
        if (result.error) {
          setPartner();
          setAuthIsDone(true);
          return;
        }
        if (result.Role === 1) setIsAdmin(true);
        setPartner(result);
        setAuthIsDone(true);
      } catch (error) {}
    };
    authMe();
  }, [setPartner, callback]);

  useEffect(() => {
    const authMe = async () => {
      try {
        const result = await authenticateClient();
        if (result.error) {
          setClient();
          setAuthIsDone(true);
          return;
        }
        setClient(result);
        setAuthIsDone(true);
      } catch (error) {}
    };
    authMe();
  }, [setClient, callback]);

  useEffect(() => {
    const getAllClients = async () => {
      const res = await getClients();
      setClients(res?.data);
    };
    getAllClients();
  }, [setClients, callback]);

  useEffect(() => {
    const getAllPartners = async () => {
      const res = await getPartners();
      setPartners(res?.data);
    };
    getAllPartners();
  }, [setPartners, callback]);

  useEffect(() => {
    const getAllServices = async () => {
      const res = await getServices();
      setServices(res?.data);
    };
    getAllServices();
  }, [setServices, callback]);

  useEffect(() => {
    const getAllPackages = async () => {
      const res = await getPackages();
      setPackages(res?.data);
    };
    getAllPackages();
  }, [setPackages, callback]);

  useEffect(() => {
    const getAllOrders = async () => {
      const res = await getOrders();
      setAllOrders(res);
    };
    getAllOrders();
  }, [setAllOrders, callback]);

  return (
    <MyContext.Provider
      value={{
        aboutUsData,
        setAboutUsData,
        loading,
        setLoading,
        error,
        setError,
        partnerLoggedIn,
        setPartnerLoggedIn,
        checkedData,
        setCheckedData,
        clientLoggedIn,
        setClientLoggedIn,
        client,
        setClient,
        partner,
        setPartner,
        authIsDone,
        setAuthIsDone,
        isAdmin,
        setIsAdmin,
        service,
        setService,
        services,
        setServices,
        onEdit,
        setOnEdit,
        callback,
        setCallback,
        id,
        setId,
        SmallImage,
        SetSmallImage,
        BigImage,
        setBigImage,
        search,
        setSearch,
        sort,
        setSort,
        order,
        setOrder,
        allOrders,
        setAllOrders,
        Package,
        setPackage,
        Packages,
        setPackages,
        packId,
        setPackId,
        partners,
        setPartners,
        clients,
        setClients,
        selectPackage,
        setSelectPackage,
        activeIndex,
        setActiveIndex,
        animating,
        setAnimating,
        stopage,
        setStopage,
        km,
        setKm,
        pickupData,
        setPickupData,
        deliveryData,
        setDeliveryData,
        stopageData,
        setStopageData,
        bookingDate,
        setBookingDate,
        stoppageBtn,
        setStoppageBtn,
        buttonDisplay,
        setButtonDisplay,
        values,
        setValues,
        ids,
        setIds,
        names,
        setNames,
        bookingTime,
        setBookingTime,
        tempOrder,
        setTempOrder,
        checkButtonDisplay,
        setcheckButtonDisplay,
        addIdSt,
        setAddIdSt,
        delAddId,
        setDelAddId,
        businessAddress,
        setBusinessAddress,
        uniRoute,
        setUniRoute,
        clientOrders,
        setClientOrders,
        partnerOrders,
        setPartnerOrders,
        allInvoices,
        setAllInvoices,
        invoice,
        setInvoice,
        clientInvoices,
        setClientInvoices,
        partnerInvoices,
        setPartnerInvoices,
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};

export default Myprovider;
