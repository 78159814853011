import axios from "axios";
import { API } from "../utils/index.jsx";

axios.defaults.baseURL = `https://kurier247.herokuapp.com`;
axios.defaults.withCredentials = true;

const extractApiError = (errAxios) => {
  return errAxios.response
    ? errAxios.response.data
    : { error: { message: "API not reachable" } };
};

export const signUpClient = async (client) => {
  try {
    const response = await axios.post(`/api/clients`, client);
    return response.data;
  } catch (err) {
    return extractApiError(err);
  }
};

export const googleLoginClient = async (data) => {
  // call login route passing in email & password
  try {
    const response = await axios.post(`/api/clients/googleLogin`, data);
    return response.data;
  } catch (err) {
    return extractApiError(err);
  }
};

export const clientLogin = async (clientCredentials) => {
  try {
    const response = await axios.post(`/api/clients/login`, clientCredentials);
    return response.data;
  } catch (err) {
    return extractApiError(err);
  }
};

export const adCLogin = async (clientCredentials) => {
  try {
    const response = await axios.post(`/api/clients/login`, clientCredentials);
    return response.data;
  } catch (err) {
    return extractApiError(err);
  }
};

export const getClients = async () => {
  try {
    const response = await axios.get(`/api/clients`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const updateStartAddress = async (id, data) => {
  try {
    const response = await axios.patch(`/api/orders/${id}/startAddress`, data);
    return response.data;
  } catch (err) {
    return extractApiError(err);
  }
};
export const updateDelAddress = async (id, data) => {
  try {
    const response = await axios.patch(
      `/api/orders/${id}/deliveryaddress`,
      data
    );
    return response.data;
  } catch (err) {
    return extractApiError(err);
  }
};

export const addstopage = async (id, data) => {
  try {
    const response = await axios.post(`/api/orders/${id}/stopaddress`, data);
    return response.data;
  } catch (err) {
    return extractApiError(err);
  }
};

export const updateStopAddress = async (id, stId, data) => {
  try {
    const response = await axios.patch(
      `/api/orders/${id}/stopaddress/${stId}`,
      data
    );
    return response.data;
  } catch (err) {
    return extractApiError(err);
  }
};

export const delStopage = async (id, stId) => {
  try {
    const response = await axios.delete(
      `/api/orders/${id}/stopaddress/${stId}`
    );
    return response.data;
  } catch (err) {
    return extractApiError(err);
  }
};

export const getPartners = async () => {
  try {
    const response = await axios.get(`/api/partners`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const signUpPartner = async (partnerOne) => {
  const response = await axios.post(
    API.POST_PARTNER_REGISTRATION_ONE,
    partnerOne
  );
  return response.data;
};

export const updatePartner = async (id, data) => {
  try {
    const response = await axios.patch(`/api/partners/${id}`, data);
    return response.data;
  } catch (error) {}
};

export const partnerLogin = async (partnerCredentials) => {
  console.log(partnerCredentials);
  try {
    const response = await axios.post(
      `/api/partners/login`,
      partnerCredentials
    );
    return response.data;
  } catch (err) {
    return extractApiError(err);
  }
};

export const addPLogin = async (data) => {
  console.log(data);
  try {
    const response = await axios.post(`/api/partners/adminlogin`, data);
    return response.data;
  } catch (err) {
    return extractApiError(err);
  }
};

export const authenticatePartner = async () => {
  try {
    const response = await axios.post(API.POST_PARTNER_AUTH);
    return response.data;
  } catch (err) {
    let error = extractApiError(err);
    return error;
  }
};
export const authenticateClient = async () => {
  try {
    const response = await axios.post(API.POST_CLIENT_AUTH);
    return response.data;
  } catch (err) {
    let error = extractApiError(err);
    return error;
  }
};

export const verifyClient = async (token) => {
  try {
    const response = await axios.post(API.POST_CLIENT_VERIFY, { token: token });
    return response.data;
  } catch (err) {
    let error = extractApiError(err);
    return error;
  }
};
export const verifyPartner = async (token) => {
  try {
    const response = await axios.post(API.POST_PARTNER_VERIFY, {
      token: token,
    });
    return response.data;
  } catch (err) {
    let error = extractApiError(err);
    return error;
  }
};

export const logOutUser = async () => {
  try {
    const response = await axios.get(API.POST_PARTNER_LOGOUT);
    return response.data;
  } catch (err) {
    return extractApiError(err);
  }
};

export const getService = async (id) => {
  try {
    const response = await axios.get(`/api/services/${id}`);
    return response;
  } catch (error) {
    return error.response && error.response.data;
  }
};

export const updateService = async (data, id) => {
  try {
    const response = await axios.patch(`/api/services/${id}`, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getServices = async () => {
  try {
    const response = await axios.get(`/api/services`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getPackages = async () => {
  try {
    const response = await axios.get(`/api/packages`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const updatePackage = async (id, data) => {
  try {
    const response = await axios.patch(`/api/packages/${id}`, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const addPackage = async (data) => {
  try {
    const response = await axios.post(`/api/packages`, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getClientOrders = async () => {
  try {
    const response = await axios.get(`/api/clients/orders`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getPartnerOrders = async () => {
  try {
    const response = await axios.get(`/api/partners/orders`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getOrders = async () => {
  try {
    const response = await axios.get(`/api/orders`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getOrder = async (id) => {
  try {
    const response = await axios.get(`/api/orders/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createOrder = async (data) => {
  try {
    const response = await axios.post(`/api/orders`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateOrder = async (id, data) => {
  try {
    const response = await axios.patch(`/api/orders/${id}`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const addBillAddress = async (id, data) => {
  try {
    const response = await axios.post(`/api/clients/${id}/billaddress`, data);
    return response.data;
  } catch (err) {
    return extractApiError(err);
  }
};

export const updateBillAddress = async (id, data) => {
  try {
    const response = await axios.patch(`/api/clients/${id}/billaddress`, data);
    return response.data;
  } catch (err) {
    return extractApiError(err);
  }
};

export const updateClient = async (id, data) => {
  try {
    const response = await axios.patch(`/api/clients/${id}`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createInvoice = async (data) => {
  try {
    const response = await axios.post(`/api/invoices`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
