import React, { useContext } from 'react';
import { LanguageContext } from '../context/Languages.jsx';
import '../css/App.css';

const PricingTwo = ({ item }) => {
  const { dictionary, userLanguage } = useContext(LanguageContext);
  
  return (
    <>
      <div
        className='pricing-two-main'
        // type="button"
        // onClick={() => setId()}
      >
        <div className='price-title2'>
          <h1>{userLanguage === 'en' ? item.Title : item.TitleDE}</h1>
          <h1>{item.PackagePrice}&nbsp;€</h1>
        </div>
        {userLanguage === 'en' ? (
          <div className='pricing-content'>
            <p dangerouslySetInnerHTML={{ __html: item.BigDetails }}></p>
          </div>
        ) : (
          <div className='pricing-content'>
            <p dangerouslySetInnerHTML={{ __html: item.BigDetailsDE }}></p>
          </div>
        )}
        <div className='price-card-footer'>
          <div className='mt-2'>* {dictionary.noTax}</div>
        </div>
      </div>
    </>
  );
};

export default PricingTwo;
