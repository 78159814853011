import "./css/App.css";
import "./scss/App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MyProvider from "./context/Myprovider";
import { LanguageProvider } from "./context/Languages";

// COMMON AREAS
import Navbar from "./components/Navbar";
import Home from "./pages/Home.jsx";
import ServiceDetails from "./components/ServiceDetails.jsx";
import AboutUs from "./components/AboutUs.jsx";
import Pricing from "./pages/Pricing.jsx";
import SearchRelocation from "./pages/SearchRelocation";
import Checkout from "./components/Checkout";
import ThankYou from "./components/ThankYou";
import Footer from "./components/Footer";
import Error404 from './components/Error404'

// PARTNERS AREAS
import PartnerLogin from "./components/partners/PartnerLogin.jsx";
import PartnerDashboard from "./components/partners/PartnerDashboard.jsx";
import PartnerPrivateRoute from "./components/partners/PartnerPrivateRoute";
import PartnerJobs from "./components/partners/PartnerJobs";
import JobDetails from "./components/partners/JobDetails";
import PartnerRegistrationOne from "./components/partners/PartnerRegistrationOne";
import PartnerRegistrationTwo from "./components/partners/PartnerRegistrationTwo";
import PartnerProfile from './components/partners/PartnerProfile'

// ADMIN AREAS
import AdminRoute from './components/admin/AdminRoute'
import DashBoard from './components/admin/DashBoard'
import CreateService from './components/admin/CreateService'
import ServiceUpdate from './components/admin/ServiceUpdate'
import CreatePackage from './components/admin/CreatePackage'
import Accounting1 from './components/admin/Accounting1'
// import Accounting from './components/admin/Accounting'

import Orders from './components/admin/Orders'
import OrderDetails from './components/admin/OrderDetails'
import Partners from './components/admin/Partners'
import Clients from './components/admin/Clients'
import PartnerDetails from './components/admin/PartnerDetails'
import ClientInfo from './components/admin/ClientInfo'

// CLIENTS AREAS
import ClientPrivateRoute from "./components/clients/ClientPrivateRoute";
import ClientLogin from "./components/clients/ClientLogin.jsx";
import ClientDashboard from "./components/clients/ClientDashboard.jsx";
import ClientOrders from "./components/clients/ClientOrders.jsx";
import ClientDetails from "./components/clients/ClientDetails";
import ClientRegister from "./components/clients/ClientRegister";
import ErrorDisplay from "./components/ErrorDisplay";

function App() {
  return (
    <MyProvider>
      <LanguageProvider>
        <Router>
          <div className="App">
            <Navbar />
            <ErrorDisplay/>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/service-details" component={ServiceDetails} />
              <Route exact path="/aboutus" component={AboutUs} />
              <Route exact path="/pricing" component={Pricing} />
              <Route
                exact
                path="/partner-registration"
                component={PartnerRegistrationOne}
              />
              <Route
                exact
                path="/client-registeration"
                component={ClientRegister}
              />
              <Route exact path="/partner-login" component={PartnerLogin} />
              <Route exact path="/client-login" component={ClientLogin} />
              <Route
                exact
                path="/search-relocation/:id"
                component={SearchRelocation}
              />
              <Route exact path="/checkout/:id" component={Checkout} />
              <Route exact path="/thanks" component={ThankYou} />

              <ClientPrivateRoute
                exact
                path="/client-dashboard"
                component={ClientDashboard}
              />
              <ClientPrivateRoute
                exact
                path="/client-jobboard/:id"
                component={ClientOrders}
              />
              <ClientPrivateRoute
                exact
                path="/client-profile"
                component={ClientDetails}
              />

              <PartnerPrivateRoute
                exact
                path="/partner-registration-two/:id"
                component={PartnerRegistrationTwo}
              />

              <PartnerPrivateRoute
                exact
                path="/partner-dashboard"
                component={PartnerDashboard}
              />
              <PartnerPrivateRoute
                exact
                path="/partner-profile"
                component={PartnerProfile}
              />
              <PartnerPrivateRoute
                exact
                path="/partner-jobs"
                component={PartnerJobs}
              />
              <PartnerPrivateRoute
                exact
                path="/job-details/:id"
                component={JobDetails}
              />

              <AdminRoute
                exact
                path="/kurier247-admin-dashboard"
                component={DashBoard}
              />
              <AdminRoute
                exact
                path="/create-service"
                component={CreateService}
              />
              <AdminRoute
                path="/update-service/:id"
                exact
                component={ServiceUpdate}
              />
              <AdminRoute
                path="/create-package"
                exact
                component={CreatePackage}
              />
              {/* <AdminRoute path="/accounts" exact component={Accounting} /> */}
              <AdminRoute path="/accounts" exact component={Accounting1} />

              <AdminRoute path="/view-orders" exact component={Orders} />
              <AdminRoute path="/order-details/:id" component={OrderDetails} />
              <AdminRoute path="/all-partners" exact component={Partners} />
              <AdminRoute path="/all-clients" exact component={Clients} />
              <AdminRoute
                path="/partner-details/:id"
                exact
                component={PartnerDetails}
              />
              <AdminRoute
                path="/client-details-info/:id"
                component={ClientInfo}
              />
              <Route path="/*" exact component={Error404} />
            </Switch>
            <Footer />
          </div>
        </Router>
      </LanguageProvider>
    </MyProvider>
  );
}

export default App;
