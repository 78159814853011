import React, { useContext } from "react";
import { LanguageContext } from "../context/Languages.jsx";
import { Link } from "react-router-dom";
import "../css/App.css";

const PricingOne = ({ item }) => {
  const { dictionary, userLanguage } = useContext(LanguageContext);

  return (
    <>
      <div className="pricing-one-main">
        <div className="price-title">
          <h1>{userLanguage === "en" ? item.Title : item.TitleDE}</h1>
          <h1>{item.PackagePrice}&nbsp;€</h1>
        </div>
        {userLanguage === "en" ? (
          <div className="pricing-content">
            <p dangerouslySetInnerHTML={{ __html: item.BigDetails }}></p>
          </div>
        ) : (
          <div className="pricing-content">
            <p dangerouslySetInnerHTML={{ __html: item.BigDetailsDE }}></p>
          </div>
        )}

        <div className="price-footer">
          <Link to={`/search-relocation/${item._id}`}>
            <button className='button-common'>{dictionary.calculate}</button>
          </Link>
          <div className="mt-2">* {dictionary.noTax}</div>
        </div>
      </div>
    </>
  );
};

export default PricingOne;
