import React, { useContext, useState } from "react";
import { Row, FormGroup, Input, Col } from "reactstrap";
import MyContext from "../context/Mycontext";
import { Autocomplete } from '@react-google-maps/api';
const BookServiceForm = (props) => {
  const { checkedData, pickupData, deliveryData, stopageData } = useContext(MyContext);
  const [address, setAddress] = useState(null);

  const onPlaceChanged = () => {
    props.change(address.getPlace().address_components, props.index);
  }  
  const onGooglePlaceLoad = (autocomplete) => {
    setAddress(autocomplete);
  };

  let finalValue = pickupData;
  if (props.type === 'delivery') {
    finalValue = deliveryData;
  } else if (props.type === 'stopage') {
    finalValue = stopageData[props.index];
  }
  
  let finalCheckedData = {};
  if (props.type === 'pickup') {
    finalCheckedData = checkedData.pickup || {};
  } else if (props.type === 'delivery') {
    finalCheckedData = checkedData.delivery || {};
  } else if (props.type === 'stopage') {
    finalCheckedData = checkedData.stopage ? checkedData.stopage[props.index] : {};
  }
  
  return (
    <>
      <h2 className={props.class}>{props.desc}</h2>
      <Row>
        <Col lg={6}>
          <FormGroup>
            {/* <Label for={`Name`}>Name *</Label> */}
            <Input
              type="text"
              name={`Name`}
              id={`${props.type}_name`}
              checked="checked"
              onChange={(e) => props.change(e, props.index)}
              placeholder="Name *"
              required
              style={
                finalCheckedData.borderColors !== undefined
                  ? finalCheckedData.borderColors.Name
                    ? { border: finalCheckedData.borderColors.Name }
                    : {}
                  : {}
              }
            />
          </FormGroup>
        </Col>

        <Col lg={6}>
          <FormGroup>
            {/* <Label for={`Street`}>Street Name *</Label> */}
              <Autocomplete onLoad={onGooglePlaceLoad} onPlaceChanged={onPlaceChanged}>
                <input
                  type='text'
                  name={`Street`}
                  placeholder='Enter street and number *'
                  onChange={(e) => props.change(e, props.index)}
                  value={finalValue.Street}
                  required
                  style={
                    finalCheckedData.borderColors !== undefined
                      ? finalCheckedData.borderColors.Street
                        ? { border: finalCheckedData.borderColors.Street }
                        : {}
                      : {}
                  }
                />
              </Autocomplete>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <FormGroup>
            {/* <Label for={`HouseNo`}>House Number *</Label> */}
            <Input
              type="text"
              name={`HouseNo`}
              id={`${props.type}_HouseNo`} 
              onChange={(e) => props.change(e, props.index)}
              placeholder='House Number *'
              required
              style={
                finalCheckedData.borderColors !== undefined
                  ? finalCheckedData.borderColors.HouseNo
                    ? { border: finalCheckedData.borderColors.HouseNo }
                    : {}
                  : {}
              }
              value={finalValue.HouseNo}
            />
          </FormGroup>
        </Col>

        <Col lg={6}>
          <FormGroup>
            {/* <Label for={`PostCode`}>ZIP Code *</Label> */}
            <Input
              type="input"
              name={`PostCode`}
              placeholder='ZIP Code *'
              id={`${props.type}_PostCode`} 
              onChange={(e) => props.change(e, props.index)}
              maxLength="5"
              pattern="[+-]?\d+(?:[.,]\d+)?"
              required
              style={
                finalCheckedData.borderColors !== undefined
                  ? finalCheckedData.borderColors.PostCode
                    ? { border: finalCheckedData.borderColors.PostCode }
                    : {}
                  : {}
              }
              value={finalValue.PostCode}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <FormGroup>
            {/* <Label for={`City`}>City *</Label> */}
            <Input
              type="text"
              name={`City`}
              id={`${props.type}_city`}
              onChange={(e) => props.change(e, props.index)}
              placeholder='City *'
              required
              style={
                finalCheckedData.borderColors !== undefined
                  ? finalCheckedData.borderColors.City
                    ? { border: finalCheckedData.borderColors.City }
                    : {}
                  : {}
              }
              value={finalValue.City}
            />
          </FormGroup>
        </Col>

        <Col lg={6}>
          <FormGroup>
            {/* <Label for={`Phone`}>Phone number</Label> */}
            <Input
              type="input"
              name={`Phone`}
              id={`${props.type}_Phone`}
              maxLength="14"
              placeholder='Phone number'
              onChange={(e) => props.change(e, props.index)}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <FormGroup>
            {/* <Label for="Comments">Comments </Label> */}

            <Input
              type="textarea"
              name={`Comments`}
              id={`${props.type}_Comments`}
              placeholder='Comments'
              onChange={(e) => props.change(e, props.index)}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default BookServiceForm;
