import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { LanguageSelector } from "./LanguageSelector.jsx";
import { FiPhone } from "react-icons/fi";
import { MdReorder } from "react-icons/md";
import MobileMenu from "./MobileMenu.jsx";
import { GrClose } from "react-icons/gr";
import { LanguageContext } from "../context/Languages.jsx";
import MyContext from "../context/Mycontext";
import { NavLink, useHistory } from "react-router-dom";
import { logOutUser } from "../helpers/apiCalls";

const NavbarPage = () => {
  const history = useHistory();
  const context = useContext(MyContext);
  const {
    isAdmin,
    setIsAdmin,
    setAuthIsDone,
    partner,
    setPartner,
    client,
    setClient,
  } = context;
  // const location = useLocation();
  const [openState, setOpenState] = useState(false);
  const [positionRight, setPositionRight] = useState(-250);

  const handleToggleMenu = (positionRight) => {
    setOpenState(!openState);
    setPositionRight(positionRight);
  };

  const handleLogout = () => {
    setIsAdmin(false);
    setPartner();
    setClient();
    setAuthIsDone(false);
    logOutUser();
    history.push("/");
  };

  /*-- Multiple language option -- */
  const { dictionary } = useContext(LanguageContext);
  return (
    <>
      <div className="navbar">
        <div className="navbar-content">
          <div className="logo">
            <a href={isAdmin ? "/kurier247-admin-dashboard" : "/"}>
              <img src="../assets/logo.png" alt="Kurier 247" />
            </a>
          </div>
          {isAdmin ? (
            <ul className="navbar-menu">
              <li className="nav-item">
                <NavLink to="/accounts">Account Summery</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/view-orders">All Orders</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/">All Services</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/create-service">Create Service</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/create-package">Create Package</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/all-partners">Partnrs Areas</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/all-clients">Clients Areas</NavLink>
              </li>
              <li className="nav-item">
                <FontAwesomeIcon
                  title="Logout"
                  onClick={handleLogout}
                  icon={faSignOutAlt}
                />
              </li>
            </ul>
          ) : (
            <ul className="navbar-menu">
              <li className="nav-item">
                <NavLink to="/pricing">{dictionary.price}</NavLink>
              </li>
              {partner ? (
                <li className="nav-item">
                  <NavLink to="/partner-dashboard">PARTNER DASHBOARD</NavLink>
                </li>
              ) : (
                <>
                  {!client && (
                    <li className="nav-item">
                      <NavLink to="/partner-login">PARTNER LOGIN</NavLink>
                    </li>
                  )}
                </>
              )}
              {client ? (
                <li className="nav-item">
                  <NavLink to="/client-dashboard">CLIENT DASHBOARD</NavLink>
                </li>
              ) : (
                <>
                  {!partner && (
                    <li className="nav-item">
                      <NavLink to="/client-login">
                        {dictionary.client} LOGIN
                      </NavLink>
                    </li>
                  )}
                </>
              )}

              <li className="nav-item">
                <LanguageSelector />
              </li>
              <li className="nav-mobile">
                <a href="tel:01736086617">
                  <FiPhone />
                  {"  "}0173 6086617
                </a>
              </li>
              {(partner || client) && (
                <li className="nav-item">
                  <FontAwesomeIcon
                    title="Logout"
                    onClick={handleLogout}
                    icon={faSignOutAlt}
                  />
                </li>
              )}
            </ul>
          )}
          <div className="navbar-details">
            {openState === true ? (
              <GrClose
                style={{ cursor: "pointer" }}
                onClick={() => handleToggleMenu(-250)}
              />
            ) : (
              <MdReorder
                style={{ cursor: "pointer" }}
                onClick={() => handleToggleMenu(0)}
              />
            )}
          </div>
        </div>
        <MobileMenu positionRight={positionRight} />
      </div>
    </>
  );
};

export default NavbarPage;
