import React, { useContext } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import MyContext from "../../context/Mycontext";
// import { Link } from "react-router-dom";
// import Loading from "../Loading";

const Accounts = () => {
  const { allOrders } = useContext(MyContext);

  // const { ExportCSVButton } = CSVExport;

  const countOrders = [];
  allOrders.map((order) => {
    if (order.OrderStatus !== "Cancel") {
      const basePrice = order.PackageId?.PackagePrice;
      const KmPrice =
        (order.PackageId?.PricePerKm * order.Total_KM).toFixed(2) * 1;
      const StopagePrice =
        (
          order.PackageId?.StoppagePrice * order.StopagesAddress?.length
        ).toFixed(2) * 1;
      const loadingPrice = (order.Load_Unload / 5) * order.PackageId?.Price5min;
      const helpingPrice =
        (order.Load_Unload_Help / 60) * order.PackageId?.PricePerHelper;
      const Netto =
        (
          basePrice +
          KmPrice +
          StopagePrice +
          loadingPrice +
          helpingPrice
        ).toFixed(2) * 1;
      const Vat = (Netto * (order.PackageId?.MwSt / 100)).toFixed(2) * 1;
      const orderTotal = (Netto + Vat).toFixed(2) * 1;
      const paidOut =
        (orderTotal - orderTotal * (order.PartnerId?.Commission / 100)).toFixed(
          2
        ) * 1;
      const grosstoCompany = (orderTotal - paidOut).toFixed(2) * 1;
      const vat = ((grosstoCompany / 119) * 19).toFixed(2) * 1;

      let modifiedOrder = {
        BookingDate: order.BookingDate.slice(0, 10),
        BookingTime: order.BookingTime,
        ClientId: order.ClientId,
        PartnerId: order.PartnerId,
        PackageId: order.PackageId,
        OrderNumber: order.OrderNumber,
        OrderStatus: order.OrderStatus,
        PaymentType: order.PaymentType,
        PaymentStatus: order.PaymentStatus,
        LastUpdate: order.updatedAt,
        Created: order.createdAt,
        Total: orderTotal,
        Paid: paidOut,
        GrossToCompany: grosstoCompany,
        Vat: vat,
        Netto: (grosstoCompany - vat).toFixed(2) * 1,
      };
      countOrders.push(modifiedOrder);
    }
    return countOrders;
  });

  const MyExport = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div className='export'>
        <button onClick={handleClick}>Export</button>
      </div>
    );
  };

  const defaultSorted = [
    {
      dataField: "LastUpdate",
      order: "asc",
    },
  ];

  const columns = [
    { dataField: "OrderNumber", text: "Order Id", sort: true },
    { dataField: "OrderStatus", text: "Order Status", sort: true },
    {
      dataField: "PartnerId.FullName",
      text: "Partner",
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: "ClientId.FullName",
      text: "Client",
      sort: true,
      filter: textFilter(),
    },
    { dataField: "PackageId.Title", text: "Package", sort: true },
    { dataField: "BookingDate", text: "Booking Date", sort: true },
    { dataField: "BookingTime", text: "Booking Time" },
    { dataField: "Total", text: "Order Total" },
    { dataField: "Paid", text: "Paid Out" },
    { dataField: "PaymentType", text: "Payment Type" },
    { dataField: "GrossToCompany", text: "Grose Total" },
    { dataField: "Vat", text: "vat-19%" },
    { dataField: "Netto", text: "Netto" },
    { dataField: "PaymentStatus", text: "Payment Status" },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    prePageText: "<",
    nextPageText: ">",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: (page, sizePerPage) => {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: (page, sizePerPage) => {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  return (
    <div className="admin-account-view">
      <div className="view-order">
      <h1>Kurier 247 Account Summery</h1>
        <ToolkitProvider
        
          keyField="Created"
          bootstrap4
          columns={columns}
          data={countOrders}
          exportCSV
        >
          {(props) => (
            <>
              <MyExport {...props.csvProps} />
              <BootstrapTable
                hover
                condensed
                defaultSorted={defaultSorted}
                filter={filterFactory()}
                pagination={pagination}
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
};

export default Accounts;
