import React, { useContext, useEffect } from "react";
import MyContext from "../../context/Mycontext";
import { addPLogin, getPartners } from "../../helpers/apiCalls";
import { Link, useHistory } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const Partners = () => {
  const { search, setSearch, sort, setSort, setPartner, partners, setPartners } =
    useContext(MyContext);
 const history = useHistory()
  useEffect(() => {
    const getAllPartners = async () => {
      const res = await getPartners();
      setPartners(res.data);
    };
    getAllPartners();
  }, [setPartners]);

  const login = async (data) => {
    let part = await addPLogin(data);
    setPartner(part);
    // history.push("/partner-dashboard");
    window.open('/partner-dashboard', '_blank')
  };

  return (
    <div className="admin-partner-areas">
      <h1>Kurier 247 Partner List</h1>
      <div className="search-filter">
        <div className="text-input">
          <input
            type="text"
            value={search}
            placeholder="Enter your search!"
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          />
          <select
            className="mx-3"
            value={sort}
            onChange={(e) => setSort(e.target.value)}
          >
            <option value="All">Select Type</option>
            <option value="SelfEmployed">SelfEmployed</option>
            <option value="Worker">Worker</option>
          </select>
          <select
            className="mr-3"
            value={sort}
            onChange={(e) => setSort(e.target.value)}
          >
            <option value="Active">Active</option>
            <option value="Pending">Pending</option>
            <option value="OnHold">OnHold</option>
            <option value="Suspend">Suspend</option>
          </select>
        </div>
        <div className="submit-button">
          <button type="submit">Search</button>
        </div>
      </div>

      <Table>
        <Thead>
          <Tr>
            <Th>Partner ID</Th>
            <Th>Partner Role</Th>
            <Th>Partner Name</Th>
            <Th>Status</Th>
            <Th>RegisterAs</Th>
            <Th>Nationality</Th>
            <Th>TaxId</Th>
            <Th>Mobile Number</Th>
            <Th>Email</Th>
            <Th>Commission</Th>
            <Th>IBAN</Th>
            <Th>Details</Th>
            {/* <Th>Login</Th> */}
          </Tr>
        </Thead>
        {partners?.map((partner) => {
          return (
            <Tbody key={partner._id}>
              <Tr>
                <Td> {partner.id} </Td>
                <Td> {partner.Role} </Td>
                <Td> {partner.FullName} </Td>
                <Td> {partner.Status} </Td>
                <Td> {partner.RegisterAs} </Td>
                <Td> {partner.Nationality} </Td>
                <Td> {partner.TaxId} </Td>
                <Td> {partner.MobileNumber} </Td>
                <Td>
                  {" "}
                  <button
                    type="button"
                    onClick={() =>
                      login({
                        Email: partner.Email,
                        Password: partner.Password,
                      })
                    }
                  >
                    {partner.Email}
                  </button>{" "}
                </Td>
                <Td> {partner.Commission} </Td>
                <Td> {partner.BankIBAN} </Td>
                <Td>
                  <Link to={`/partner-details/${partner._id}`}>View</Link>
                </Td>
                {/* <Td> <button type='button' onClick={handleLogin}>Login</button> </Td> */}
              </Tr>
            </Tbody>
          );
        })}
      </Table>
    </div>
  );
};

export default Partners;
