import React, { useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import MyContext from "../../context/Mycontext";
import { useForm } from "react-hook-form";
import axios from "axios";
import { updateService, getService } from "../../helpers/apiCalls";

const ServiceUpdate = (props) => {

  let { id } = useParams();
  const history = useHistory();
  const { service, setService, BigImage, setBigImage, setLoading, isAdmin, setCallback, callback } = useContext(MyContext);

  const { register, handleSubmit, reset } = useForm({
    defaultValues: service,
  });

  useEffect(() => {
    const getData = async () => {
      let result = await getService(id);
      setService(result.data);
      reset(result.data);
    };
    getData();
  }, [id, setService, reset]);

  const uploadImage = async e =>{
    e.preventDefault();
    try {
      if (!isAdmin) return alert("You're not an admin");
      const files = e.target.files;
      let formData = new FormData();
      for (let file of files) {
        if (!file) return alert("File not exist.");
        if (file.size > 1024 * 1024) return alert("Size too large!");
        if (file.type !== "image/jpeg" && file.type !== "image/png")
          return alert("File format is incorrect.");
        formData.append("files", file);
      }
      setLoading(true);
      const res = await axios.post("/api/upload", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      setLoading(false);
      setBigImage(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDestroy = async (image) => {
    try {
      if (!isAdmin) return alert("You're not an admin");
      setLoading(true);
      await axios.post("/api/destroy", { public_id: image.public_id });
      setLoading(false);
      setBigImage(
        BigImage.filter((item) => item.public_id !== image.public_id)
      );
    } catch (err) {
      alert(err.response.data.msg);
    }
  };

  const onSubmit = async (data) => {
    const res = await updateService({...data, BigImage}, service._id);
    if (!res.error) {
      setService(res.data);
      setCallback(!callback);
      history.push("/home");
    }
  };

  // console.log(service);
  const imageList = BigImage?.map((image) => {
    return (
      <div key={image.public_id}>
        <img src={image.url} alt="" />
        <span onClick={() => handleDestroy(image)}>X</span>
      </div>
    );
  });

  return (
    <div className="update_services full-width-height">
      <div className="image-upload">
        <input type="file" name="file" onChange={uploadImage} multiple />
        <div>{imageList}</div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <input type="file" {...register('BigImage')} /> */}
        <div className="servicePage-field">
          <div className="inputs">
            <input
              type="number"
              placeholder="Service ID"
              defaultValue={service.ServiceID}
              {...register("ServiceID")}
            />
            <input
              type="number"
              placeholder="SortIndex"
              defaultValue={service.SortIndex}
              {...register("SortIndex")}
            />
            <input
              type="number"
              placeholder="Status"
              defaultValue={service.Status}
              {...register("Status")}
            />
            <input
              type="number"
              placeholder="isRelocationService"
              defaultValue={service.IsRelocationService}
              {...register("IsRelocationService")}
            />
            <input
              type="text"
              placeholder="Title English"
              defaultValue={service.Title}
              {...register("Title")}
            />
            <input
              type="text"
              placeholder="Title German"
              defaultValue={service.TitleDe}
              {...register("TitleDe")}
            />
          </div>

          <textarea
            maxLength="250"
            type="text"
            placeholder="SmallDetails English"
            aria-multiline={true}
            rows="3"
            defaultValue={service.SmallDetails}
            {...register("SmallDetails")}
          />
          <textarea
            maxLength="250"
            type="text"
            placeholder="SmallDetails German"
            rows="3"
            defaultValue={service.SmallDetailsDe}
            {...register("SmallDetailsDe")}
          />
          <textarea
            type="text"
            rows="9"
            placeholder="Service Details English"
            defaultValue={service.BigDetails}
            {...register("BigDetails")}
          />
          <textarea
            type="text"
            rows="9"
            placeholder="Service Details German"
            defaultValue={service.BigDetailsDe}
            {...register("BigDetailsDe")}
          />

          <button type="submit">Update</button>
        </div>
      </form>
    </div>
  );
};

export default ServiceUpdate;
