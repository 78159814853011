import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../context/Languages.jsx";
import { useHistory, useParams } from "react-router-dom";
import { getPartnerOrders } from "../../helpers/apiCalls.js";

const JobDetails = () => {
  const { goBack } = useHistory();
  const params = useParams();
  const { dictionary } = useContext(LanguageContext);
  const [reqOrder, setReqOrder] = useState({});

  useEffect(() => {
    const getData = async () => {
      let items = await getPartnerOrders();
      items.filter((order) => {
        if (order._id === params.id) {
          setReqOrder(order);
        }
        return order;
      });
    };
    getData();
  }, [params.id]);

  console.log(reqOrder);

  let start =
    reqOrder.StartAddress?.Street +
    " " +
    reqOrder.StartAddress?.HouseNo +
    ", " +
    reqOrder.StartAddress?.PostCode +
    " " +
    reqOrder.StartAddress?.City;
  let end =
    reqOrder.DeliveryAddress?.Street +
    " " +
    reqOrder.DeliveryAddress?.HouseNo +
    ", " +
    reqOrder.DeliveryAddress?.PostCode +
    " " +
    reqOrder.DeliveryAddress?.City;

  console.log(end);

  return (
    <>
      <div className="Partner-job-details">
        <div className="job-board-card mb-3">
          <div className="title-field">
            <img src="../assets/logo.png" alt="Kurier 247" />
            <h1 className="profile-sub-title p-4">
              {dictionary.order} Details
            </h1>
          </div>
          <div className="jobdetails-body">
            <div className="field">
              Date: {reqOrder.BookingDate?.slice(0, 10)}{" "}
            </div>
            <div className="field">Time: {reqOrder.BookingTime} </div>
            <div className="field">Package: {reqOrder.PackageId?.Title} </div>
            <div className="field">Payment Type: {reqOrder.PaymentType} </div>
            <div className="field">
              {dictionary.clien} Name: {reqOrder.ClientId?.FullName}{" "}
            </div>
            <div className="field">
              Contact Number: {reqOrder.ClientId?.Phone}
            </div>
          </div>
          <div className="job-addresses">
            <div className="address-field">
              <h2>Start Address</h2>
              <p>Name: {reqOrder.StartAddress?.Name}</p>
              <p>
                Phone:{" "}
                <a href={reqOrder.StartAddress?.Phone}>
                  {reqOrder.StartAddress?.Phone}
                </a>
              </p>
              <p>Note: {reqOrder.StartAddress?.Comments}</p>
              <p>
                Address:{" "}
                <a
                  href={`https://google.com/maps/?hl=de&q=${start}`}
                  target="_blank" rel="noreferrer"
                >
                  {start}
                </a>
              </p>
            </div>
            {reqOrder.StopagesAddress?.map((stopage, index) => {
              let stop =
                stopage?.Street +
                " " +
                stopage?.HouseNo +
                ", " +
                stopage?.PostCode +
                " " +
                stopage?.City;
              return (
                <div className="address-field" key={stopage._id}>
                  <h2>Stopage {`${index + 1}`}</h2>
                  <p>Name: {stopage?.Name}</p>
                  <p>
                    Phone: <a href={stopage?.Phone}>{stopage?.Phone}</a>
                  </p>
                  <p>Note: {stopage?.Comments}</p>

                  <p>
                    Address:{" "}
                    <a
                      href={`https://google.com/maps/?hl=de&q=${stop}`}
                      target="_blank" rel="noreferrer"
                    >
                      {stop}
                    </a>
                  </p>
                </div>
              );
            })}
            <div className="address-field">
              <h2>Delivery Address</h2>
              <p>Name: {reqOrder.DeliveryAddress?.Name}</p>
              <p>
                Phone:{" "}
                <a href={reqOrder.DeliveryAddress?.Phone}>
                  {reqOrder.DeliveryAddress?.Phone}
                </a>
              </p>
              <p>Note: {reqOrder.DeliveryAddress?.Comments}</p>
              <p>
                Address:{" "}
                <a
                  href={`https://google.com/maps/?hl=de&q=${end}`}
                  target="_blank" rel="noreferrer"
                >
                  {end}
                </a>
              </p>
            </div>
          </div>
          <button
            onClick={goBack}
            type="button"
            id="return"
            className="button-common"
          >
            Return
          </button>
        </div>
      </div>
    </>
  );
};
export default JobDetails;
