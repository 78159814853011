import React, { useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { LanguageContext } from "../../context/Languages";
import MyContext from "../../context/Mycontext";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { getClientOrders } from "../../helpers/apiCalls";

const ClientDashboard = (props) => {
  const { dictionary } = useContext(LanguageContext);
  const { clientOrders, setClientOrders, client } = useContext(MyContext);
  const history = useHistory();
  const [pTable, setPTable] = useState(false);
  const [aTable, setATable] = useState(false);
  const [cTable, setCTable] = useState(false);

  useEffect(() => {
    const getData = async () => {
      let items = await getClientOrders();
      setClientOrders(items);
    };
    getData();
  }, [setClientOrders]);

  let pendings = [];
  let accepts = [];
  let completes = [];

  clientOrders?.forEach((item) => {
    if (item.OrderStatus === "Pending") pendings.push(item);
    if (item.OrderStatus === "Accept") accepts.push(item);
    if (item.OrderStatus === "Done") completes.push(item);
  });

  console.log(completes);

  const goToProfile = () => {
    // console.log("profile button clicked",  client);
    history.push(`/client-profile`)
  };

  const goToPendingJob = () => {
    setPTable(true);
    setATable(false);
    setCTable(false);
  };
  const goToAcceptJob = () => {
    setPTable(false);
    setATable(true);
    setCTable(false);
  };
  const goToCompleteJob = () => {
    setPTable(false);
    setATable(false);
    setCTable(true);
  };

// const goTo = ()=>{
//   history.push(`/client-jobboard/${item._id}`)
// }


  return (
    <>
      <div className="client-dashboard">
        <div className="client-dashboard-header">

        <h1>{dictionary.client} DASHBOARD</h1>
        </div>
        <div className="client-dashboard-body">
          <div className="client-profile">
            <h1>{dictionary.client} PROFILE</h1>
            <div className="info">
              <div>Name :</div>
              <p> {client.FullName} </p>
            </div>
            <div className="info">
              <div>Email :</div>
              <p> {client.Email} </p>
            </div>
            <div className="info">
              <div>Phone :</div>
              <p> {client.Phone} </p>
            </div>
            <button
              type="submit"
              className="button-common"
              onClick={goToProfile}
            >
              DETAILS INFO
            </button>
          </div>
          <div className="client-orders">
            <h1>JOB ORDERS</h1>
            <div className="client-orders-buttons">
              <button
                type="submit"
                className="button-common"
                onClick={goToPendingJob}
              >
                PENDING --&gt; {pendings.length}
              </button>
              <button
                type="submit"
                className="button-common"
                onClick={goToAcceptJob}
              >
                ACCEPT --&gt; {accepts.length}
              </button>
              <button
                type="submit"
                className="button-common"
                onClick={goToCompleteJob}
              >
                COMPLETE --&gt; {completes.length}
              </button>
            </div>
          </div>
        </div>
        <div className="client-order-tables">
          {pendings.length > 0 && pTable && (
            <Table>
              <Thead>
                <Tr>
                  <Th>Order ID</Th>
                  <Th>Package</Th>
                  <Th>Date</Th>
                  <Th>Time</Th>
                  <Th>Total</Th>
                  <Th>Update</Th>
                </Tr>
              </Thead>
              {pendings.map((item) => {
                return (
                  <Tbody key={item._id}>
                    <Tr>
                      <Td>{item.OrderNumber}</Td>
                      <Td>{item.PackageId?.Title}</Td>
                      <Td>{item.BookingDate.slice(0, 10)}</Td>
                      <Td>{item.BookingTime}</Td>
                      <Td>{(
                          item.PackageId.PackagePrice +
                          item.Total_KM * item.PackageId.PricePerKm +
                          item.StopagesAddress.length * item.PackageId.StoppagePrice +
                          ((item.PackageId.PackagePrice +
                            item.Total_KM * item.PackageId.PricePerKm +
                            item.StopagesAddress.length * item.PackageId.StoppagePrice) *
                            item.PackageId.MwSt) /
                            100
                        ).toFixed(2) * 1}  €</Td>
                      <Td><Link to={`/client-jobboard/${item._id}`}>View</Link></Td>
                    </Tr>
                  </Tbody>
                );
              })}
            </Table>
          )}
          {accepts.length > 0 && aTable && (
            <Table>
              <caption>To update any info, Please call 01736086617.</caption>
              <Thead>
                <Tr>
                  <Th>Order ID</Th>
                  <Th>Package</Th>
                  <Th>Date</Th>
                  <Th>Time</Th>
                  <Th>Service Partner</Th>
                  <Th>Total</Th>
                </Tr>
              </Thead>
              {accepts.map((item) => {
                return (
                  <Tbody key={item._id}>
                    <Tr>
                      <Td>{item.OrderNumber}</Td>
                      <Td>{item.PackageId?.Title}</Td>
                      <Td>{item.BookingDate.slice(0, 10)}</Td>
                      <Td>{item.BookingTime}</Td>
                      <Td>
                        name: {item.PartnerId.FullName} <br/>
                        Mob: <a href="tel:+"{...item.PartnerId.MobileNumber}>{item.PartnerId.MobileNumber}</a>                        
                      </Td>
                      <Td>{(
                          item.PackageId.PackagePrice +
                          item.Total_KM * item.PackageId.PricePerKm +
                          item.StopagesAddress.length * item.PackageId.StoppagePrice +
                          ((item.PackageId.PackagePrice +
                            item.Total_KM * item.PackageId.PricePerKm +
                            item.StopagesAddress.length * item.PackageId.StoppagePrice) *
                            item.PackageId.MwSt) /
                            100
                        ).toFixed(2) * 1}  €</Td>
                    </Tr>
                  </Tbody>
                );
              })}
            </Table>
          )}
          {completes.length > 0 && cTable && (
            <Table>
              <Thead>
                <Tr>
                  <Th>Order ID</Th>
                  <Th>Package</Th>
                  <Th>Date</Th>
                  <Th>Time</Th>
                  <Th>Total</Th>
                  <Th>Print</Th>
                </Tr>
              </Thead>
              {completes.map((item) => {
                return (
                  <Tbody key={item._id}>
                    <Tr>
                      <Td>{item.OrderNumber}</Td>
                      <Td>{item.PackageId?.Title}</Td>
                      <Td>{item.BookingDate.slice(0, 10)}</Td>
                      <Td>{item.BookingTime}</Td>
                      <Td>{item.Total}</Td>
                      <Td>@mdo</Td>
                    </Tr>
                  </Tbody>
                );
              })}
            </Table>
          )}
        </div>
      </div>
    </>
  );
};
export default ClientDashboard;
