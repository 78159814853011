import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../context/Languages";
import { Link, useHistory } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import MyContext from "../../context/Mycontext";
import {
  updateOrder,
  getPartnerOrders,
  createInvoice,
} from "../../helpers/apiCalls";
import OrderValidation from "../../validation/OrderValidation";

const PartnerDashboard = () => {
  const localizer = momentLocalizer(moment);
  const { dictionary, userLanguage } = useContext(LanguageContext);
  const history = useHistory();
  const context = useContext(MyContext);
  const {
    partner,
    allOrders,
    setOrder,
    setCallback,
    callback,
    setPartnerOrders,
    partnerOrders,
    setCheckedData,
    checkedData,
    setInvoice,
  } = context;

  const goToMyJob = () => {
    history.push(`/partner-jobs`);
  };

  const goTos = (data) => {
    history.push(`/job-details/${data.id}`);
  };

  let pending = [];
  allOrders.forEach((item) => {
    if (item.OrderStatus === "Pending" || item.OrderStatus === "Reject")
      pending.push(item);
  });

  // console.log(partner);

  useEffect(() => {
    const getData = async () => {
      let items = await getPartnerOrders();
      setPartnerOrders(items);
    };
    getData();
  }, [setPartnerOrders, callback]);

  const [finalOrders, setFinalOrders] = useState([]);
  useEffect(() => {
    let pastOrders = [];
    partnerOrders.forEach((item) => {
      const bookingDate = new Date(item.BookingDate);
      const oDay = bookingDate.getDate();
      let currentDate = new Date();
      const cDay = currentDate.getDate();
      const cHours = currentDate.getHours();
      let bookingHours = item.BookingTime?.slice(7, 9);
      if (item.OrderStatus === "Accept" && bookingDate < currentDate) {
        pastOrders.push(item);
      } else if (
        item.OrderStatus === "Accept" &&
        oDay === cDay &&
        cHours >= parseInt(bookingHours)
      ) {
        pastOrders.push(item);
      }
      return item;
    });
    setFinalOrders(pastOrders);
  }, [partnerOrders]);

  // console.log("final order", finalOrders);

  let orderData = {
    PartnerId: partner._id,
    OrderStatus: "Accept",
  };

  const update = async (id) => {
    const res = await updateOrder(id, orderData);
    setOrder(res);
    setCallback(!callback);
  };

  const inputHandler = (e, index) => {
    e.preventDefault();
    const id = e.target.id;
    const val = e.target.value;
    let tempOrders = [...finalOrders];
    tempOrders[index] = {
      ...tempOrders[index],
      [id]: val,
    };
    setFinalOrders(tempOrders);
    setCheckedData({});
  };

  const orderComplete = async (data) => {
    let checkedData = OrderValidation(data);
    setCheckedData(checkedData);
    if (checkedData.success) {
      let orderData = {
        ...data,
        OrderStatus: "Done",
      };
      try {
        let newInvoice = await createInvoice({
          ...orderData,
          OrderId: data._id,
        });
        let res = await updateOrder(data._id, orderData);
        setInvoice(newInvoice);
        setOrder(res);
      } catch (error) {
        console.log(error);
      }
      setCallback(!callback);
    }
  };

  return (
    <>
      <div className="partner-dashboard">
        <div className="dashboard-body">
          <div className="dashboard-heading">
            <Link to="/partner-profile">
              <h1>{partner.FullName}</h1>
            </Link>
            <button onClick={() => goToMyJob()} className="button-common">
              My JobBoard
            </button>
          </div>
          {partner.Status === "Active" && (
            <div className="general-job-table m-3 text-center">
              <h2 className="general-job-table-title">General Job Board</h2>
              <Table>
                <Thead>
                  <Tr>
                    <Th>{dictionary.order} ID</Th>
                    <Th>Package</Th>
                    <Th>Date</Th>
                    <Th>Time</Th>
                    <Th>Pickup</Th>
                    <Th>Dropoff</Th>
                    <Th>Stopage</Th>
                    <Th>{dictionary.price}</Th>
                    <Th>Update</Th>
                  </Tr>
                </Thead>
                {pending.map((item) => {
                  return (
                    <Tbody key={item._id}>
                      <Tr>
                        <Td>DE-{item.OrderNumber}</Td>
                        <Td>
                          {userLanguage === "en"
                            ? item.PackageId.Title
                            : item.PackageId.TitleDE}
                        </Td>
                        <Td>{item.BookingDate.slice(0, 10)}</Td>
                        <Td>{item.BookingTime}</Td>
                        <Td>{item.StartAddress.PostCode}</Td>
                        <Td>{item.DeliveryAddress.PostCode}</Td>
                        <Td>{item.StopagesAddress?.length}</Td>
                        <Td>
                          {(
                            item.PackageId.PackagePrice +
                            item.Total_KM * item.PackageId.PricePerKm +
                            item.StopagesAddress.length *
                              item.PackageId.StoppagePrice +
                            ((item.PackageId.PackagePrice +
                              item.Total_KM * item.PackageId.PricePerKm +
                              item.StopagesAddress.length *
                                item.PackageId.StoppagePrice) *
                              item.PackageId.MwSt) /
                              100
                          ).toFixed(2) * 1}{" "}
                          €
                        </Td>
                        <Td>
                          <button
                            type="button"
                            onClick={() => update(item._id)}
                          >
                            Accept
                          </button>
                        </Td>
                      </Tr>
                    </Tbody>
                  );
                })}
              </Table>
            </div>
          )}
          <div className="general-job-table m-3 text-center">
            <h2 className="text-center general-job-table-title">Past Order</h2>
            <Table>
              <Thead>
                <Tr>
                  <Th>{dictionary.order} ID</Th>
                  <Th>Date</Th>
                  <Th>Time</Th>
                  <Th>Package</Th>
                  <Th>Payment Method</Th>
                  <Th>Laden/Warten</Th>
                  <Th>Minuten Helfer</Th>
                  <Th>Empfängername</Th>
                  <Th>Update</Th>
                </Tr>
              </Thead>
              {finalOrders.map((order, index) => {
                return (
                  <Tbody key={index}>
                    <Tr>
                      <Td> DE-{order.OrderNumber} </Td>
                      <Td> {order.BookingDate?.slice(0, 10)} </Td>
                      <Td> {order.BookingTime?.slice(0, 12)} </Td>
                      <Td> {order.PackageId?.Title} </Td>
                      <Td> {order.PaymentType} </Td>
                      <Td>
                        <input
                          type="number"
                          name="Load_Unload"
                          id="Load_Unload"
                          index={index}
                          value={order.Load_Unload}
                          onChange={(e) => inputHandler(e, index)}
                          required
                          style={
                            checkedData.borderColors !== undefined
                              ? checkedData.borderColors.Load_Unload
                                ? {
                                    border:
                                      checkedData.borderColors.Load_Unload,
                                  }
                                : {}
                              : {}
                          }
                        />
                      </Td>
                      <Td>
                        <input
                          type="number"
                          name="Load_Unload_Help"
                          id="Load_Unload_Help"
                          index={index}
                          value={order.Load_Unload_Help}
                          onChange={(e) => inputHandler(e, index)}
                          required
                          style={
                            checkedData.borderColors !== undefined
                              ? checkedData.borderColors.Load_Unload_Help
                                ? {
                                    border:
                                      checkedData.borderColors.Load_Unload_Help,
                                  }
                                : {}
                              : {}
                          }
                        />
                      </Td>
                      <Td>
                        <input
                          type="input"
                          name="ReciverName"
                          id="ReciverName"
                          index={index}
                          value={order.ReciverName}
                          onChange={(e) => inputHandler(e, index)}
                          required
                          style={
                            checkedData.borderColors !== undefined
                              ? checkedData.borderColors.ReciverName
                                ? {
                                    border:
                                      checkedData.borderColors.ReciverName,
                                  }
                                : {}
                              : {}
                          }
                        />
                      </Td>
                      <Td>
                        <button
                          type="button"
                          onClick={() => orderComplete(order)}
                        >
                          Complete
                        </button>
                      </Td>
                    </Tr>
                  </Tbody>
                );
              })}
            </Table>
          </div>

          <div className="general-job-calender m-3 text-center">
            <h3 className="text-center calender-title">
              Calendar with all Scheduled Booking
            </h3>
            <Calendar
              localizer={localizer}
              events={partnerOrders.map((order) => {
                // console.log(order);
                if (order.OrderStatus !== "Reject") {
                  let year = order.BookingDate.slice(0, 4);
                  let month = order.BookingDate.slice(5, 7);
                  let day = order.BookingDate.slice(8, 10);
                  let startHour = order.BookingTime.slice(0, 2);
                  let startMin = order.BookingTime.slice(3, 5);
                  let endHour = order.BookingTime.slice(7, 9);
                  let endMin = order.BookingTime.slice(10, 12);
                  return {
                    title: order.PackageId.Title,
                    start: new Date(year, month - 1, day, startHour, startMin),
                    end: new Date(year, month - 1, day, endHour, endMin),
                    id: order._id,
                  };
                }
                return order
              })}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              views={["day", "week", "month"]}
              step={30}
              defaultView="month"
              selectable
              onSelectEvent={(event) => goTos(event)}
            />
          </div>
        </div>
        {/* } */}
      </div>
    </>
  );
};
export default PartnerDashboard;
