const BASEURL = process.env.REACT_APP_SERVER_URL || "https://kurier247.herokuapp.com";

export const API = {
  /* -- Pricing API -- */
  GET_PACKAGE: `${BASEURL}/api/packages`,

  /* --Our services API-- */
  GET_SERVICES: `${BASEURL}/api/services`,
  UPDATE_SERVICE: `${BASEURL}/api/services`,
  // GET_SERVICES_DE: `${BASEURL}/api/services/de`,

  GET_ABOUTUS: `${BASEURL}/api/html`,
  // GET_ABOUTUS_DETAILS: `${BASEURL}/api/About/GetDetails/`,

  /* --Partner registration-- */
  POST_PARTNER_REGISTRATION_ONE: `${BASEURL}/api/partners`,
  POST_PARTNER_AUTH:`${BASEURL}/api/partners/authP`,
  POST_PARTNER_ADMIN_LOGIN:`${BASEURL}/api/partners/adminlogin`,
  POST_PARTNER_LOGOUT:`${BASEURL}/api/partners/logout`,
  PATCH_PARTNER_REGISTRATION_TWO: `${BASEURL}/api/partners/:id`,

  POST_PARTNER_VERIFY:`${BASEURL}/api/partners/verify`,

  POST_UPLOAD_FILE: `${BASEURL}/api/partners/uploadFile`,

  /* --Client registration-- */
  POST_CLIENT_REGISTRATION: `${BASEURL}/api/clients`, 
  POST_CLIENT_AUTH:`${BASEURL}/api/clients/authC`,
  POST_CLIENT_LOGIN:`${BASEURL}/api/clients/login`,
  POST_CLIENT_VERIFY:`${BASEURL}/api/clients/verify`,

}



export const language = [
  { value: "bulgarian", label: "Bulgarian" },
  { value: "czech", label: "Czech" },
  { value: "english", label: "English" },
  { value: "french", label: "French" },
  { value: "german", label: "German" },
  { value: "italian", label: "Italian" },
  { value: "lithuanian", label: "Lithuanian" },
  { value: "polish", label: "Polish" },
  { value: "portuguese", label: "Portuguese" },
  { value: "romanian", label: "Romanian" },
  { value: "spanish", label: "Spanish" },
  { value: "swedish", label: "Swedish" },
  { value: "turkish", label: "Turkish" },
  { value: "others", label: "Others" },
];

export const interest = [
  { value: "bike", label: "Bike Courier" },
  { value: "pkw", label: "PKW Courier" },
];