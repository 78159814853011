import React from "react";

const ThankYou = () => {
  return (
    <div className="full-width-height">
      <img
        src="assets/logo.png"
        style={{ width: "149px" }}
        alt="KURIER 247"
      /><br/>
      <h1>Booking Submition Successful !</h1><br/>
      <h3>We would like to inform you that, We have recived your Booking.</h3>
      <h3>We will check all information and update you as soon as possible.</h3>
      <h3>
        {" "}
        You will recived an email, with booking status as soon as there is any
        update on your Booking.
      </h3>
      <h3>Thank you for your trust with Kurier 247</h3>
    </div>
  );
};

export default ThankYou;
