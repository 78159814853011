import React, { useContext, useState } from "react";
import MyContext from "../../context/Mycontext";
import { useForm } from "react-hook-form";
import { updatePartner } from "../../helpers/apiCalls";

const PartnerProfile = () => {
  const { partner, setPartner, callback, setCallback } = useContext(MyContext);
  const [btn, setBtn] = useState(false);
  const { register, handleSubmit } = useForm();

  console.log(partner);

  const onSubmit = async (data) => {
    const res = await updatePartner(partner._id, data);
    setPartner(res);
    setCallback(!callback);
  };

  return (
    <div className="full-width-height">
      <div className="partner-profile">
        <h1>Partner Profile</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-body">
            <div className="form">
              <label>Salutation : </label>            
              <select {...register("Salutation")} onClick={() => setBtn(true)} >
                <option value={partner?.Salutation}>
                  {partner?.Salutation}
                </option>
                <option
                  value="Mr"
                  style={
                    partner?.Salutation === "Mr"
                      ? { display: "none" }
                      : { display: "block" }
                  }
                >
                  Mr
                </option>
                <option
                  value="Ms"
                  style={
                    partner?.Salutation === "Ms"
                      ? { display: "none" }
                      : { display: "block" }
                  }
                >
                  Ms
                </option>
              </select>
            </div>
            <div className="form">
              <label>FirstName : </label>
              <input
                onClick={() => setBtn(true)}
                type="text"
                placeholder="FirstName"
                defaultValue={partner.FirstName}
                {...register("FirstName")}
              />
            </div>
          </div>
          <div className="form-body">
            <div className="form">
              <label>FamilyName : </label>
              <input
                onClick={() => setBtn(true)}
                type="text"
                placeholder="FamilyName"
                defaultValue={partner.FamilyName}
                {...register("FamilyName")}
              />
            </div>
            <div className="form">
              <label>FullName : </label>
              <input type="text" defaultValue={partner.FullName} disabled />
            </div>
          </div>
          <div className="form-body">
            <div className="form">
              <label>Street : </label>
              <input
                onClick={() => setBtn(true)}
                type="text"
                placeholder="Street"
                defaultValue={partner.Street}
                {...register("Street")}
              />
            </div>
            <div className="form">
              <label>HouseNumber : </label>
              <input
                onClick={() => setBtn(true)}
                type="text"
                placeholder="HouseNumber"
                defaultValue={partner.HouseNumber}
                {...register("HouseNumber")}
              />
            </div>
          </div>
          <div className="form-body">
            <div className="form">
              <label>PostCode : </label>
              <input
                onClick={() => setBtn(true)}
                type="input"
                maxLength="5"
                placeholder="PostCode"
                defaultValue={partner.PostCode}
                {...register("PostCode")}
              />
            </div>
            <div className="form">
              <label>City : </label>
              <input
                onClick={() => setBtn(true)}
                type="text"
                placeholder="City"
                defaultValue={partner.City}
                {...register("City")}
              />
            </div>
          </div>
          <div className="form-body">
            <div className="form">
              <label>Country : </label>
              <input
                onClick={() => setBtn(true)}
                type="text"
                placeholder="Country"
                defaultValue={partner.Country}
                {...register("Country")}
              />
            </div>
            <div className="form">
              <label>Email : </label>
              <input
                onClick={() => setBtn(true)}
                type="email"
                placeholder="Email"
                defaultValue={partner.Email}
                {...register("Email")}
                disabled
              />
            </div>
          </div>
          <div className="form-body">
            <div className="form">
              <label>MobileNumber : </label>
              <input
                onClick={() => setBtn(true)}
                type="tel"
                placeholder="MobileNumber"
                defaultValue={partner.MobileNumber}
                {...register("MobileNumber")}
              />
            </div>
            <div className="form">
              <label>Nationality : </label>
              <input
                onClick={() => setBtn(true)}
                type="text"
                placeholder="Nationality"
                defaultValue={partner.Nationality}
                {...register("Nationality")}
              />
            </div>
          </div>
          <div className="form-body">
            <div className="form">
              <label>Status : </label>
              <input
                onClick={() => setBtn(true)}
                type="text"
                placeholder="Status"
                defaultValue={partner.Status}
                {...register("Status")}
                disabled
              />
            </div>
            <div className="form">
              <label>RegisterAs : </label>
              <select {...register("RegisterAs")} onClick={() => setBtn(true)} disabled>
                <option value={partner?.RegisterAs}>
                  {partner?.RegisterAs}
                </option>
                <option
                  value="Self-Employed"
                  style={
                    partner?.RegisterAs === "Self-Employed"
                      ? { display: "none" }
                      : { display: "block" }
                  }
                >
                  Self-Employed
                </option>
                <option
                  value="Worker"
                  style={
                    partner?.PaymentType === "Worker"
                      ? { display: "none" }
                      : { display: "block" }
                  }
                >
                  Worker
                </option>
              </select>
            </div>
          </div>
          <div className="form-body">
            <div className="form">
              <label>emailIsVerified : </label>
              <input
                onClick={() => setBtn(true)}
                type="boolean"
                placeholder="emailIsVerified"
                defaultValue={partner.emailIsVerified}
                {...register("emailIsVerified")}
                disabled
              />
            </div>
            <div className="form">
              <label>Commission : </label>
              <input
                onClick={() => setBtn(true)}
                type="number"
                placeholder="Commission"
                defaultValue={partner.Commission}
                {...register("Commission")}
                disabled
              />
            </div>
          </div>
          <div className="form-body">
            <div className="form">
              <label>InterestedWorkingAreas : </label>
              <input
                onClick={() => setBtn(true)}
                type="password"
                placeholder="InterestedWorkingAreas"
                defaultValue={partner.InterestedWorkingAreas}
                {...register("InterestedWorkingAreas")}
                disabled
              />
            </div>
            <div className="form">
              <label>Password : </label>
              <input
                onClick={() => setBtn(true)}
                type="password"
                placeholder="Password"
                defaultValue={partner.Password}
                {...register("Password")}
              />
            </div>
          </div>
          <div className="form-body">
            <div className="form">
              <label>Date Of Birth : </label>
              <input
                onClick={() => setBtn(true)}
                type="date"
                id="DOB"
                placeholder="Date"
                dateformat="yyyy-MM-dd"
                defaultValue={partner.DOB?.slice(0, 10)}
                {...register("DOB")}
              />
            </div>
            <div className="form">
              <label>IdPassNumber : </label>
              <input
                onClick={() => setBtn(true)}
                type="text"
                placeholder="IdPassNumber"
                defaultValue={partner.IdPassNumber}
                {...register("IdPassNumber")}
                disabled
              />
            </div>
          </div>
          <div className="form-body">
            <div className="form">
              <label>Validity : </label>
              <input
                onClick={() => setBtn(true)}
                type="date"
                id="Validity"
                placeholder="Date"
                defaultValue={partner.Validity?.slice(0, 10)}
                dateformat="yyyy-MM-dd"
                {...register("Validity")}
                disabled
              />
            </div>
            <div className="form">
              <label>InterestedWorkingField : </label>
              <input
                onClick={() => setBtn(true)}
                type="text"
                placeholder="InterestedWorkingField"
                defaultValue={partner.InterestedWorkingField}
                {...register("InterestedWorkingField")}
                disabled
              />
            </div>
          </div>
          <div className="form-body">
            <div className="form">
              <label>ChooseKm : </label>
              <input
                onClick={() => setBtn(true)}
                type="number"
                placeholder="ChooseKm"
                defaultValue={partner.ChooseKm}
                {...register("ChooseKm")}
              />
            </div>
            <div className="form">
              <label>LanguageSkills : </label>
              <input
                onClick={() => setBtn(true)}
                type="text"
                placeholder="LanguageSkills"
                defaultValue={partner.LanguageSkills}
                {...register("LanguageSkills")}
              />
            </div>
          </div>
          <div className="form-body">
            <div className="form">
              <label>IdPassportFront : </label>
              <input
                onClick={() => setBtn(true)}
                type="text"
                placeholder="IdPassportFront"
                defaultValue={partner.IdPassportFront}
                {...register("IdPassportFront")}
                disabled
              />
            </div>
            <div className="form">
              <label>IdPassportBack : </label>
              <input
                onClick={() => setBtn(true)}
                type="text"
                placeholder="IdPassportBack"
                defaultValue={partner.IdPassportBack}
                {...register("IdPassportBack")}
                disabled
              />
            </div>
          </div>
          <div className="form-body">
            <div className="form">
              <label>BusinessRegistration : </label>
              <input
                onClick={() => setBtn(true)}
                type="text"
                placeholder="BusinessRegistration"
                defaultValue={partner.BusinessRegistration}
                {...register("BusinessRegistration")}
                disabled
              />
            </div>
            <div className="form">
              <label>TaxIdFile : </label>
              <input
                onClick={() => setBtn(true)}
                type="text"
                placeholder="TaxIdFile"
                defaultValue={partner.TaxIdFile}
                {...register("TaxIdFile")}
                disabled
              />
            </div>
          </div>
          <div className="form-body">
            <div className="form">
              <label>IdentificationNumber : </label>
              <input
                onClick={() => setBtn(true)}
                type="input"
                placeholder="IdentificationNumber"
                defaultValue={partner.IdentificationNumber}
                {...register("IdentificationNumber")}
                disabled
              />
            </div>
            <div className="form">
              <label>TaxId : </label>
              <input
                onClick={() => setBtn(true)}
                type="input"
                placeholder="TaxId"
                defaultValue={partner.TaxId}
                {...register("TaxId")}
                disabled
              />
            </div>
          </div>
          <div className="form-body">
            <div className="form">
              <label>ProfilePic : </label>
              <input
                onClick={() => setBtn(true)}
                type="text"
                placeholder="ProfilePic"
                defaultValue={partner.ProfilePic}
                {...register("ProfilePic")}
              />
            </div>
            <div className="form">
              <label>BankIBAN : </label>
              <input
                onClick={() => setBtn(true)}
                type="input"
                placeholder="BankIBAN"
                defaultValue={partner.BankIBAN}
                {...register("BankIBAN")}
                disabled
              />
            </div>
          </div>
          <div className="form-body">
            <div className="form">
              <label>PoliceClearence : </label>
              <input
                onClick={() => setBtn(true)}
                type="text"
                placeholder="PoliceClearence"
                defaultValue={partner.PoliceClearence}
                {...register("PoliceClearence")}
              />
            </div>
            <div className="form">
              <label>googleId : </label>
              <input
                onClick={() => setBtn(true)}
                type="input"
                placeholder="googleId"
                defaultValue={partner.googleId}
                {...register("googleId")}
                disabled
              />
            </div>
          </div>
          {btn && (
            <div className="form-submit-button">
              <button className="button-common">Update and Save</button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default PartnerProfile;
