import React, { useContext } from "react";
import MyContext from "../../context/Mycontext";
import { Link} from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const Clients = () => {
  const { search, setSearch, clients, partner } = useContext(MyContext);

  const login = async(data)=>{
    console.log(data, partner._id);
    // let client = await loginC(params.id, data)
    // console.log(client);
  }

  return (
    <div className="admin-client-areas">
      <h1>Kurier 247 Client List</h1>
      <div className="search-filter">
        <div className="text-input">
          <input
            type="text"
            value={search}
            placeholder="Enter your search!"
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          />
        </div>
        <div className="submit-button">
          <button type="submit">Search</button>
        </div>
      </div>

      <Table>
        <Thead>
          <Tr>
            <Th>Client ID</Th>
            <Th>FirstName</Th>
            <Th>LastName</Th>
            <Th>CompanyName</Th>
            <Th>Mobile Number</Th>
            <Th>Email</Th>
            <Th>Details</Th>
          </Tr>
        </Thead>
        {clients?.map((client) => {
          return (
            <Tbody key={client._id}>
              <Tr>
                <Td> {client.id} </Td>
                <Td> {client.FirstName} </Td>
                <Td> {client.LastName} </Td>
                <Td> {client.CompanyName} </Td>
                <Td> {client.Phone} </Td>
                <Td><button type="button" onClick={()=> login(client.Email)}>{client.Email}</button></Td>
                <Td>
                  <Link to={`/client-details-info/${client._id}`}>View</Link>
                </Td>
              </Tr>
            </Tbody>
          );
        })}
      </Table>
    </div>
  );
};

export default Clients;
