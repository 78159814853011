import React, { useContext, useEffect } from "react";
import { LanguageContext } from "../context/Languages";
import { API } from "../utils/index.jsx";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Card,
  CardImg,
  CardImgOverlay,
  Button,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import ServiceOne from "../components/ServiceOne.jsx";
import axios from "axios";
import MyContext from "../context/Mycontext";
const imageOne = "../assets/3.png";
const imageTwo = "../assets/133.png";
const imageThree = "../assets/333.png";
const imageFour = "../assets/3333.png";

const items = [
  {
    src: imageOne,
    altText: "",
    caption: "",
  },
  {
    src: imageTwo,
    altText: "",
    caption: "",
  },
  {
    src: imageThree,
    altText: "",
    caption: "",
  },
  {
    src: imageFour,
    altText: "",
    caption: "",
  },
];

const Home = (props) => {
  const history = useHistory()
  const { dictionary, userLanguage } = useContext(LanguageContext);
  const {services, aboutUsData, setAboutUsData, activeIndex, setActiveIndex, animating, setAnimating} = useContext(MyContext)

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} style={{ width: "100%", height: "400px" }} />
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        />
      </CarouselItem>
    );
  });  

  useEffect(() => {
    function getAboutUs() {
      let requestUrl = API.GET_ABOUTUS;
      axios
        .get(`${requestUrl}`)
        .then((res) => {
          setAboutUsData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getAboutUs();
  }, [userLanguage, setAboutUsData]);

  const goTo = ()=>{
    history.push('/pricing')
  }

  return (
    <>
      <div className="home-main">
        <div className="main-slide-bar">
          <Carousel activeIndex={activeIndex} next={next} previous={previous}>
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
              color="red"
            />
            {slides}
            <div className="" style={{ display: "none" }}>
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
              />
            </div>
          </Carousel>
        </div>

        <div className="book-service">
          <h1>{dictionary.bookservice}</h1>
          <h2>Quick and Easy</h2>
          <div className="book-service-form-group">
            <input type='input' maxLength='5' className="zip-code" placeholder="  ZIP Code..." />
            <Button className="zip-code-search-btn" color="primary" onClick={goTo}>
              SEARCH NOW
            </Button>
          </div>
        </div>

        <div className="main-services-bar">
            <h1>-- {dictionary.ourServices} --</h1>
          <div className="service-area">
              { services?.map((item, index) => (
                    <div className='service-body' key={index}>
                      <ServiceOne
                        id={item._id}
                        imgS={item.SmallImage}
                        imgL={item.BigImage}
                        title={item.Title}
                        title_De={item.TitleDe}
                        contentS={item.SmallDetails}
                        contentS_De={item.SmallDetailsDe}
                        contentL={item.BigDetails}
                        contentL_De={item.BigDetailsDe}
                      />
                    </div>
                  ))}
            </div>
        </div>
        <div className="main-about-us-bar text-center">
            <h1>-- {dictionary.aboutUs} --</h1>
          <Card inverse className="mt-2">
            <CardImg
              width="100%"
              src="../assets/aboutBG.jpg"
              height="350px"
              alt="Card image cap"
            />
            <CardImgOverlay>
              <div className="text-left about-us-details col-lg-6 p-3">
                {aboutUsData?.length !== undefined && aboutUsData.length > 0
                  ? aboutUsData[0].SmallDetails
                  : ""}
              </div>
              <div className="mt-2 text-left p-3">
                <Link to={"aboutus"}>
                  <button className='button-common'>{dictionary.knowmore}</button>
                </Link>
              </div>
            </CardImgOverlay>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Home;
